import React, { useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { MiddleBtn } from "src/styles/Button";
import {
  ChangeCautionBox,
  ChangeCautiontText,
  ChangeCautiontTitle,
} from "src/styles/ChangeFace";

interface UploadFailProps {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadFail = (props: UploadFailProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Helmet>
        <title>Face Flip | File upload fail</title>
      </Helmet>
      <Fade>
        <ChangeCautionBox>
          <ChangeCautiontTitle>Oops!</ChangeCautiontTitle>
          <ChangeCautiontText>
            업로드하는 사진의 용량이 너무 커요!
            <br />
            (가능한 크기: 3,024 * 4,032px 이하)
          </ChangeCautiontText>
          <MiddleBtn
            id="error"
            btcolor="orange"
            onClick={() => {
              handleButtonClick();
            }}
          >
            다시 올리기
          </MiddleBtn>
          <input
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            id="sideImageUpload"
            onChange={props.handleUpload}
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </ChangeCautionBox>
      </Fade>
    </>
  );
};

export default UploadFail;
