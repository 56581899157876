import { API, GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

export const amplifyFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const result = await (API.graphql({
      query,
      variables: variables || {},
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }) as Promise<GraphQLResult<TData>>);

    if (result.errors) {
      const message = result.errors
        ? result.errors[0].message
        : "GraphQL fetching error";
      throw new Error(message);
    }

    return result.data!;
  };
};
