import React from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/store";
import { ChangeButton } from "src/styles/Button";
import { MainSubTitle, MainTitle } from "src/styles/Main";

const MainModellTitle = () => {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  // 태그매니저 events
  const tagManagerArgs = {
    dataLayer: {
      event: "swap",
      source: utmSource ? utmSource : "direct",
      page_title: htmlTitle?.innerText,
      model_age: user.age,
      model_gender: user.gender,
      model_needs: user.needs,
    },
  };

  return (
    <>
      <div>
        <MainTitle>
          버추얼 모델?
          <br className="break-m" /> 어떻게 될 수 있냐고요?
          <br />
          모델풀 등록 절차를
          <br className="break-m" /> 직접 체험해보세요.
        </MainTitle>
        <MainSubTitle>
          <span>
            플립션은 버추얼 모델 에이전시로, 모델 분들에게 새로운 가능성을
            열어드립니다!
          </span>
          <br />
          <span>
            외국인 모델 공고는 나를 위한게 아니라고요? 플립션과 함께라면 내
            얼굴에 외국인 얼굴을
          </span>
          <br />
          <span>합성해서 외국인 모델로도 활동할 수 있습니다.</span>
        </MainSubTitle>
      </div>
      <ChangeButton
        id="swap"
        onClick={() => {
          TagManager.dataLayer(tagManagerArgs);
          navigate("/change");
        }}
      >
        버추얼 모델 체험하기
      </ChangeButton>
    </>
  );
};

export default MainModellTitle;
