import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  SignupBox,
  SignupClose,
  SignupEtcInputBox,
  SignupFormControl,
  SignupInput,
  SignupInputBox,
  SignupInputDiv,
  SignupLabel,
  SignupSelect,
} from "src/styles/Login";
import { SelectMenuProps, selectIcon } from "./PersonalSignup";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { SignupFormType } from "./SignUp";
import { Helmet } from "react-helmet-async";

interface CompanySignupProps {
  register: UseFormRegister<SignupFormType>;
  errors: FieldErrors<SignupFormType>;
  job: string;
  position: string;
  handleJobChange: (event: SelectChangeEvent<unknown>) => void;
  handlePositionChange: (event: SelectChangeEvent<unknown>) => void;
  needs: string[];
  handleChange: (event: SelectChangeEvent<unknown>) => void;
  signUpCancel: () => void;
}

const CompanySignup = (props: CompanySignupProps) => {
  const navigate = useNavigate();

  const jobCategory = [
    "패션 브랜드",
    "뷰티 브랜드",
    "기타 브랜드",
    "마케팅 에이전시",
    "모델 에이전시",
    "일반 기업의 마케팅팀",
    "IT 관련 기업",
    "기타",
  ];

  const position = [
    "사원/대리",
    "과장/차장",
    "부장",
    "임원",
    "대표",
    "매니저(직급 없음)",
  ];

  const needsList = [
    "모델과의 일정 조율이 어려움",
    "모델을 신뢰하기 어려움",
    "원하는 모델이 없음(얼굴)",
    "원하는 모델이 없음(바디)",
    "외국인 모델을 구하기 어려움",
    "에이전시 비용이 비쌈",
    "모델 비용이 비쌈",
    "초상권 이슈로 활용하지 못하게 됨",
    "기타",
  ];

  const isEtcIncluded = props.needs.includes("기타");

  const [personName, setPersonName] = React.useState<string[]>([]);

  return (
    <SignupBox>
      <Helmet>
        <title>Face Flip | Sign up_brand</title>
      </Helmet>
      <SignupClose
        onClick={() => {
          if (window.confirm("회원가입을 취소하시겠습니까?")) {
            props.signUpCancel();
          }
        }}
      />
      <SignupInputBox>
        <SignupLabel>1. 회사</SignupLabel>
        <SignupInputDiv>
          <SignupInput
            type="text"
            placeholder=" ( 근무하시는 회사 이름을 적어주세요 ) "
            {...props.register("name", {
              required: "회사 이름을 입력해주세요",
              pattern: {
                value: /^[A-za-z0-9가-힣]{1,10}$/,
                message:
                  "10글자 이하의 한글, 영문 대소문자, 숫자를 입력해주세요",
              },
            })}
          />
          {props.errors.name && <p>{props.errors.name.message}</p>}
        </SignupInputDiv>
      </SignupInputBox>
      <SignupInputBox>
        <SignupLabel>2. 산업군</SignupLabel>
        <SignupFormControl>
          <SignupSelect
            value={props.job}
            onChange={props.handleJobChange}
            IconComponent={selectIcon}
            displayEmpty
            MenuProps={SelectMenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              산업군을 선택해주세요
            </MenuItem>
            {jobCategory.map((item: string) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SignupSelect>
        </SignupFormControl>
      </SignupInputBox>
      <SignupInputBox>
        <SignupLabel>3. 직함</SignupLabel>
        <SignupFormControl>
          <SignupSelect
            value={props.position}
            onChange={props.handlePositionChange}
            IconComponent={selectIcon}
            displayEmpty
            MenuProps={SelectMenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              직함을 선택해주세요
            </MenuItem>
            {position.map((item: string) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SignupSelect>
        </SignupFormControl>
      </SignupInputBox>
      <SignupInputBox>
        <SignupLabel>4. 니즈</SignupLabel>
        <SignupFormControl>
          <InputLabel
            id="select-helper-label"
            sx={{ fontFamily: "'NanumSquareAcr', sans-serif" }}
          >
            모델 구인 시 불편했던 점을 알려주세요
          </InputLabel>
          <SignupSelect
            multiple
            id="select-helper-label"
            value={props.needs}
            onChange={props.handleChange}
            IconComponent={selectIcon}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected: any) => selected.join(", ")}
            MenuProps={SelectMenuProps}
          >
            <MenuItem value={[]} disabled>
              모델 구인 시 불편했던 점을 알려주세요 (복수 선택 가능)
            </MenuItem>
            {needsList.map((need) => (
              <MenuItem key={need} value={need}>
                <Checkbox checked={props.needs.indexOf(need) > -1} />
                <ListItemText primary={need} />
              </MenuItem>
            ))}
          </SignupSelect>
        </SignupFormControl>
      </SignupInputBox>
      <SignupEtcInputBox isEtcIncluded={isEtcIncluded}>
        <SignupLabel>4.1 기타 사유</SignupLabel>
        <SignupInputDiv>
          <SignupInput
            type="text"
            placeholder=" ( 기타 사유를 입력해주세요 ) "
            {...props.register("etc", { required: isEtcIncluded })}
          />
          {props.errors.etc && <p>사유를 입력해주세요</p>}
        </SignupInputDiv>
      </SignupEtcInputBox>
    </SignupBox>
  );
};

export default CompanySignup;
