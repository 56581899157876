import { createSlice } from "@reduxjs/toolkit";

export interface PageState {
  loginOpen: boolean;
}

const initialState: PageState = {
  loginOpen: false,
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setOpen: (state) => {
      state.loginOpen = true;
    },
    setClose: (state) => {
      state.loginOpen = false;
    },
  },
});

export const { setOpen, setClose } = pageSlice.actions;

export default pageSlice.reducer;
