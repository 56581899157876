import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/Fliption_logo_1.png";
import Login from "src/features/login/Login";
import { LoginButton, RequestButton } from "src/styles/Button";
import {
  HeaderButtonBox,
  HeaderContainer,
  MobileIcon,
} from "src/styles/Header";
import MobileNav from "./MobileNav";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useDispatch } from "react-redux";
import { setSNS, clearAuth } from "src/store/slice/userSlice";
import FliptionLink from "src/features/request/FliptionLink";
import TagManager from "react-gtm-module";
import { Auth } from "aws-amplify";
import { setOpen } from "src/store/slice/pageSlice";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mainType = useSelector((state: RootState) => state.user.mainType);
  const user = useSelector((state: RootState) => state.user);
  const loginOpen = useSelector((state: RootState) => state.page.loginOpen);

  // 태그매니저 events
  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  const modelArgs = {
    dataLayer: {
      event: "apply_model",
      page_title: htmlTitle?.innerText,
      source: utmSource ? utmSource : "direct",
      model_age: user.age,
      model_gender: user.gender,
      model_needs: user.needs,
    },
  };

  const myPageArgs = {
    dataLayer: {
      event: "mypage",
      source: utmSource ? utmSource : "direct",
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_name: user.name,
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  // 로그인 메뉴
  // const [open, setOpen] = useState(false);

  // 모바일 메뉴
  const [mOpen, setMOpen] = useState(false);
  // 링크 메뉴
  const [linkOpen, setLinkOpen] = useState(false);

  // 현재 width
  const [winW, setWinW] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWinW(window.innerWidth);
      if (window.innerWidth > 768) {
        setMOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const kakaoAPI = process.env.REACT_APP_KAKAO_REST_API;
  const naverClient = process.env.REACT_APP_NAVER_CLIENT_ID;
  const googleClient = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  // const redirect_uri = "http://localhost:3000";
  const redirect_uri = "https://www.fliption.ai";

  // const naver_redirect_uri = "http://localhost:3000/oauth";
  const naver_redirect_uri = "https://www.fliption.ai/oauth";

  // 카카오
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoAPI}&redirect_uri=${redirect_uri}&response_type=code`;
  const handleKakaoLogin = () => {
    dispatch(setSNS({ sns: "kakao" }));
    window.location.href = kakaoURL;
  };

  // 네이버
  const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClient}&redirect_uri=${naver_redirect_uri}&state=fliption`;
  const handleNaverLogin = () => {
    dispatch(setSNS({ sns: "naver" }));
    window.location.href = naverURL;
  };

  // 구글
  const googleURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClient}&response_type=token&redirect_uri=${redirect_uri}&scope=https://www.googleapis.com/auth/userinfo.email`;
  const handleGoogleLogin = () => {
    dispatch(setSNS({ sns: "google" }));
    window.location.href = googleURL;
  };

  // 로그아웃
  const logout = async () => {
    // if (user.sns === "kakao") {
    //   kakaoLogout();
    // } else {
    navigate("/");
    dispatch(clearAuth());
    // }
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("code");
    try {
      await Auth.signOut();
    } catch (err) {
      console.log(err);
    }
  };

  // 회원가입 취소
  const signUpCancel = async () => {
    if (location.pathname.includes("/signup")) {
      dispatch(clearAuth());
      try {
        await Auth.signOut();
      } catch (err) {
        console.log(err);
      }
    }
  };

  // 플립션 모델 지원
  const applyModel = () => {
    TagManager.dataLayer(modelArgs);
    window.open("https://forms.gle/KFrSUh253bdZAWD88", "_blank");
  };

  const needsLogin = () => {
    alert("로그인이 필요합니다");
    dispatch(setOpen());
  };

  return (
    <HeaderContainer>
      <img
        src={logo}
        alt="logo"
        onClick={() => {
          navigate("/");
          signUpCancel();
        }}
      />
      {winW > 768 ? (
        <nav>
          <HeaderButtonBox>
            <li>
              <LoginButton
                selected={linkOpen ? "orange" : "grey"}
                onClick={() => {
                  setLinkOpen(true);
                }}
              >
                플립션소개
              </LoginButton>
            </li>
            {user.authenticated && (
              <li>
                <LoginButton
                  id="mypage"
                  selected={
                    location.pathname.includes("/mypage")
                      ? loginOpen || linkOpen
                        ? "grey"
                        : "orange"
                      : "grey"
                  }
                  onClick={() => {
                    TagManager.dataLayer(myPageArgs);
                    navigate("/mypage");
                  }}
                >
                  마이페이지
                </LoginButton>
              </li>
            )}
            {user.authenticated ? (
              <li>
                <LoginButton selected="grey" onClick={logout}>
                  로그아웃
                </LoginButton>
              </li>
            ) : (
              <li>
                <LoginButton
                  selected={loginOpen ? "orange" : "grey"}
                  onClick={() => {
                    dispatch(setOpen());
                  }}
                >
                  로그인
                </LoginButton>
              </li>
            )}
            {user.authenticated ? (
              user.type === "기업" ? (
                <li>
                  <RequestButton
                    onClick={() => {
                      navigate("/request");
                    }}
                  >
                    무료 테스트 신청
                  </RequestButton>
                </li>
              ) : (
                <li>
                  <RequestButton
                    id="apply_model"
                    onClick={() => {
                      user.authenticated ? applyModel() : needsLogin();
                    }}
                  >
                    플립션 모델풀 지원
                  </RequestButton>
                </li>
              )
            ) : mainType === "company" ? (
              <li>
                <RequestButton
                  onClick={() => {
                    navigate("/request");
                  }}
                >
                  회사 소개서 요청
                </RequestButton>
              </li>
            ) : (
              <li>
                <RequestButton
                  id="apply_model"
                  onClick={() => {
                    user.authenticated ? applyModel() : needsLogin();
                  }}
                >
                  플립션 모델풀 지원
                </RequestButton>
              </li>
            )}
          </HeaderButtonBox>
        </nav>
      ) : (
        <>
          <MobileIcon
            open={mOpen}
            onClick={() => {
              setMOpen(!mOpen);
            }}
          >
            <i>
              <span />
            </i>
          </MobileIcon>
          <MobileNav
            open={mOpen}
            setOpen={setMOpen}
            setLinkOpen={setLinkOpen}
            // kakaoLogout={kakaoLogout}
            logout={logout}
          />
        </>
      )}
      {/* <div ref={naverRef} id="naverIdLogin" style={{ display: "none" }} /> */}
      <Login
        handleKakaoLogin={handleKakaoLogin}
        handleNaverLogin={handleNaverLogin}
        handleGoogleLogin={handleGoogleLogin}
      />
      <FliptionLink open={linkOpen} setOpen={setLinkOpen} />
    </HeaderContainer>
  );
};

export default Header;
