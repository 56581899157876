import React, { useEffect } from "react";
import { WithdrawalCompleteInner } from "../../styles/User";
import {
  RequestCompleteBox,
  RequestCompleteInner,
  RequestCompleteText,
} from "../../styles/Request";
import { MiddleBtn } from "../../styles/Button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Fade } from "react-awesome-reveal";

const WithdrawalComplete = () => {
  const navigate = useNavigate();

  return (
    <Fade>
      <Helmet>
        <title>Face Flip | Withdrawal done</title>
      </Helmet>
      <RequestCompleteInner>
        <RequestCompleteBox>
          <RequestCompleteText>
            탈퇴가 성공적으로
            <br />
            완료되었습니다.
          </RequestCompleteText>
          <MiddleBtn
            btcolor="orange"
            onClick={() => {
              navigate("/");
            }}
          >
            메인 페이지로
          </MiddleBtn>
        </RequestCompleteBox>
      </RequestCompleteInner>
    </Fade>
  );
};

export default WithdrawalComplete;
