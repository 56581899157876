import React, { useEffect, useState } from "react";
import { RequestContainer } from "../../styles/Request";
import RequestDone from "./RequestDone";
import RequestForm from "./RequestForm";
import TagManager from "react-gtm-module";

const Request = () => {
  const [done, setDone] = useState(false);

  // gtm event
  const htmlTitle = document.querySelector("title");
  const utmSource = sessionStorage.getItem("utm_source");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSource ? utmSource : "direct",
      },
    });
  }, []);

  return (
    <RequestContainer>
      {done ? <RequestDone /> : <RequestForm setDone={setDone} />}
    </RequestContainer>
  );
};

export default Request;
