import axios from "axios";

export const kakaoWithdrawalApi = axios.create({
  baseURL: "https://kapi.kakao.com/v1/user/unlink",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export async function postRefreshToken() {
  try {
    const response = await axios.post(
      "https://kauth.kakao.com/oauth/token",
      {
        grant_type: "refresh_token",
        client_id: process.env.REACT_APP_KAKAO_CLIENT_ID,
        refresh_token: window.sessionStorage.getItem("refresh_token"),
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const newToken = response.data.access_token;
    const newRefreshToken = response.data.refresh_token;
    sessionStorage.setItem("access_token", newToken);
    sessionStorage.setItem("refresh_token", newRefreshToken);

    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

kakaoWithdrawalApi.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    const {
      response: { status },
    } = err;
    if (status === 401) {
      return postRefreshToken().then(() => {
        return axios.request(err.config);
      });
    }
    return Promise.reject(err);
  }
);
