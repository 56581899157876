import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { setOpen } from "src/store/slice/pageSlice";
import { RootState } from "src/store/store";

const PrivateRoute = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  if (!user.authenticated) {
    alert("로그인이 필요합니다");
    dispatch(setOpen());
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
