import styled, { css } from "styled-components";
import loadingImg from "src/assets/images/loading.png";

// - 로딩창
export const LoadingBox = styled.div`
  ${({ theme }) => theme.flexCol}
  justify-content: center;
  gap: 30px;
`;
export const LoadingTextBox = styled.div`
  ${({ theme }) => theme.flexCol}
  justify-content: center;
  gap: 5px;
`;
export const LoadingTitle = styled.p`
  font-size: 55px;
  font-weight: 800;
  line-height: 72px;
  text-align: center;
  ${(props) => props.theme.media.mobile`
    font-size: 40px;
    line-height: 64px;
  `}
`;
export const LoadingText = styled.span`
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  ${(props) => props.theme.media.mobile`
  font-size: 12px;
  `}
`;
export const LoadingIcon = styled.div<{ progress: number }>`
  position: relative;
  width: 280px;
  height: 280px;
  background: url(${loadingImg});
  background-size: cover;
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ progress }) => 100 - progress}%;
    filter: grayscale(100%);
    background: url(${loadingImg});
    background-size: cover;
  }
  @media screen and (max-width: 500px) {
    width: 220px;
    height: 220px;
  }
`;

// - 설문 페이지
export const SurveyContainer = styled.form`
  ${({ theme }) => theme.flexCol}
  /* gap: 70px; */
  margin: 78px 15px 0;
  width: 100%;
  height: 100%;
  ${(props) => props.theme.media.mobile`
  margin: 15% 15px;
  `}
`;
export const Survey1ContentsBox = styled.div`
  ${({ theme }) => theme.optionBox}
  width: 100%;
  max-width: 732px;
  height: 100%;
  max-height: 469px;
  gap: 30px;
  padding: 48px 10px;
  ${(props) => props.theme.media.mobile`
  padding: 20px;
  `}
`;
export const Survey2ContentsBox = styled.div`
  ${({ theme }) => theme.optionBox}
  width: 100%;
  max-width: 732px;
  height: 100%;
  max-height: 630px;
  gap: 30px;
  margin-top: 40px;
  padding: 48px 10px;
  ${(props) => props.theme.media.mobile`
  margin-top: 10%;
  padding: 20px;
  max-height: 500px;
  `}
`;
export const SurveyTitle = styled.h2`
  font-weight: 800;
  font-size: 55px;
  line-height: 72px;
  text-align: center;
  color: #fff;
  margin-bottom: 70px;
  @media screen and (max-width: 1150px) {
    font-size: 48px;
    line-height: 64px;
  }
  ${(props) => props.theme.media.tablet`
    font-size: 43px;
    line-height: 54px;
    margin-bottom: 50px;
  `}
  ${(props) => props.theme.media.mobile`
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 40px;
  `}
`;
export const SurveySubTitle = styled.p`
  font-weight: 800;
  font-size: 50px;
  line-height: 65px;
  text-align: center;
  color: #676767;
  margin-bottom: 30px;
  ${(props) => props.theme.media.mobile`
    font-size: 43px;
    line-height: 46px;
    margin-bottom: 15px;
  `}
  ${(props) => props.theme.media.small`
    font-size: 35px;
    line-height: 40px;
  `}
`;
export const SurveyQuestion = styled.p`
  font-weight: 700;
  font-size: 31px;
  line-height: 40px;
  text-align: center;
  color: #787878;
  ${(props) => props.theme.media.tablet`
    font-size: 27px;
    line-height: 35px;
  `}
  ${(props) => props.theme.media.mobile`
    font-size: 25px;
    line-height: 32px;
  `}
  @media screen and (max-width: 400px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

// - 변환 결과 페이지
export const ResultInner = styled.div`
  position: relative;
  display: block;
  padding: 56px 5% 157px;
  word-break: keep-all;
  ${(props) => props.theme.media.mobile`
  padding: 10% 10% 120px;
  `}
`;
export const ResultTitle = styled.h2`
  font-weight: 800;
  font-size: 55px;
  line-height: 72px;
  text-align: center;
  color: #fff;
  margin-bottom: 5px;
  @media screen and (max-width: 1150px) {
    font-size: 48px;
    line-height: 64px;
  }
  ${(props) => props.theme.media.tablet`
    font-size: 43px;
    line-height: 54px;
  `}
  ${(props) => props.theme.media.mobile`
    font-size: 35px;
    line-height: 45px;
  `}
  ${(props) => props.theme.media.small`
    font-size: 30px;
  `}
`;
export const ResultSubTitle = styled.p`
  margin-bottom: 49px;
  font-size: 32px;
  line-height: 41.76px;
  text-align: center;
  color: #fff;
  ${(props) => props.theme.media.tablet`
    margin-bottom: 40px;
    font-size: 27px;
    line-height: 35px;
  `}
  ${(props) => props.theme.media.mobile`
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
  `}
`;
export const ResultImgContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 77.5px;
  ${(props) => props.theme.media.tablet`
    gap: 50px;
    > img {
      width: 50px;
    }
  `}
  ${(props) => props.theme.media.mobile`
  flex-direction: column;
    gap: 30px;
    > img {
      width: 30px;
      transform: rotate(90deg);
    }
  `}
`;
export const ResultImgBox = styled.div<{ border: string }>`
  ${({ theme }) => theme.flexCol}
  gap: 11px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 67px;
    background: ${({ border }) =>
      border === "white"
        ? "#ebebeb"
        : "linear-gradient(180deg, #ff6410 0%, #ce9643 100%)"};
    padding: ${({ border }) => (border === "white" ? "8px" : "12px")};
  }
  img {
    width: 100%;
    max-width: 333px;
    height: 500px;
    border-radius: 60px;
    object-fit: cover;
  }
  ${(props) => props.theme.media.tablet`
> div {
  img {
    max-width: calc(333px * 0.8);
    height: 400px;
  }
}
  `}
  ${(props) => props.theme.media.mobile`
> div {
  img {
    max-width: calc(333px * 0.6);
    height: 300px;
  }
}
  `}
`;
export const ResultImgTitle = styled.p<{ color: string }>`
  font-size: 27px;
  font-weight: 800;
  line-height: 35px;
  color: ${(props) => props.color};
  ${(props) => props.theme.media.tablet`
    font-size: 22px;
    line-height: 27px;
  `}
`;
export const ResultBtBox = styled.div`
  position: absolute;
  left: calc(50% - 370px / 2);
  bottom: -83px;
  display: flex;
  justify-content: center;
  gap: 18px;
  width: 370px;
  ${(props) => props.theme.media.tablet`
  width: 318px;
  gap: 15px;
  left: calc(50% - 315px / 2);
  `}
  ${(props) => props.theme.media.mobile`
  width: 100%;
  bottom: -70px;
  left: 0;
  `}
`;

// - 서비스 평가 창
export const RatingContainer = styled.div`
  ${({ theme }) => theme.flexCol}
  padding: 50px 70px;
  ${(props) => props.theme.media.mobile`
    padding: 35px;
  .MuiRating-root {
    font-size: 60px !important;
  }
  `}
  ${(props) => props.theme.media.small`
  .MuiRating-root {
    font-size: 50px !important;
  }
  `}
@media screen and (max-width: 350px) {
    .MuiRating-root {
      font-size: 40px !important;
    }
  }
`;
export const RatingTitle = styled.h3`
  margin-bottom: 67px;
  font-size: 47px;
  font-weight: 800;
  line-height: 52px;
  text-align: center;
  color: #676767;
  word-break: keep-all;
  ${(props) => props.theme.media.tablet`
  margin-bottom: 45px;
  font-size: 42px;
  line-height: 45px;
  `}
  ${(props) => props.theme.media.mobile`
  margin-bottom: 35px;
  font-size: 35px;
  line-height: 40px;
  `}
  ${(props) => props.theme.media.small`
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 34px;
`}
`;
export const RatingSubTitle = styled.p`
  margin-bottom: 21px;
  font-size: 27px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #9f9f9f;
  ${(props) => props.theme.media.mobile`
  font-size: 24px;
  line-height: 28px;
  `}
`;
export const NoRatingBt = styled.div`
  width: 100%;
  height: 103px;
  ${(props) => props.theme.media.tablet`
  height: 84px;
`}
  ${(props) => props.theme.media.mobile`
  height: 72.5px;
`}
  ${(props) => props.theme.media.small`
  height: 70px;
`}
`;
export const RatingResultBox = styled.div`
  ${({ theme }) => theme.flexCol}
  gap: 37px;
  padding: 50px;
  ${(props) => props.theme.media.tablet`
  gap: 30px;
  padding: 40px;
  `}
  ${(props) => props.theme.media.mobile`
  gap: 25px;
  padding: 30px;
  `}
`;
export const RatingResultTitle = styled.h3`
  font-size: 47px;
  font-weight: 800;
  line-height: 52px;
  text-align: center;
  color: #676767;
  ${(props) => props.theme.media.tablet`
  font-size: 42px;
  line-height: 45px;
  `}
  ${(props) => props.theme.media.mobile`
  font-size: 35px;
  line-height: 40px;
  `}
`;
export const RatingResultText = styled.p`
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #9f9f9f;
  font-weight: 700;
  b {
    color: #ff6410;
    cursor: pointer;
  }
  span {
    font-weight: 400;
  }
  ${(props) => props.theme.media.tablet`
  line-height: 27px;
  `}
  ${(props) => props.theme.media.mobile`
  font-size: 20px;
  line-height: 24px;
  `}
`;
