import React, { useEffect, useState } from "react";
import {
  MainBanner,
  MainBox,
  MainContainer,
  MainFliptionInfo,
} from "../../styles/Main";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { setAuth, clearAuth, setId } from "src/store/slice/userSlice";
import { Helmet } from "react-helmet-async";
import Loading from "../../components/Loading";
import { Auth } from "aws-amplify";
import { Maybe, useUpdateUserMutation, useUserByEmailQuery } from "src/lib/api";
import free from "src/assets/images/FREE.png";
import Marquee from "react-fast-marquee";
import MainBrandTitle from "./MainBrandTitle";
import MainModellTitle from "./MainModelTitle";
import TagManager from "react-gtm-module";

interface loginResultType {
  authenticated: boolean;
  sns: string;
  id: string;
  name: string;
  needs: Maybe<Maybe<string>[]>;
  type?: Maybe<string>;
  age?: Maybe<string>;
  gender?: Maybe<string>;
  industryGroup?: Maybe<string>;
  title?: Maybe<string>;
}

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mainType = useSelector((state: RootState) => state.user.mainType);
  const user = useSelector((state: RootState) => state.user);

  // 유저 확인
  const { data: userData } = useUserByEmailQuery({
    email: user.id || "",
  });

  // 로그인 로딩 상태
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  // gtm event
  const htmlTitle = document.querySelector("title");

  // utm source
  const utmSource = new URLSearchParams(window.location.search).get(
    "utm_source"
  );

  const utmSourceText = sessionStorage.getItem("utm_source");

  // 유저 정보 source 업데이트
  const { mutate } = useUpdateUserMutation();

  useEffect(() => {
    if (utmSource) {
      sessionStorage.setItem("utmSource", utmSource);
    }
  }, [utmSource]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSourceText ? utmSourceText : "direct",
      },
    });
  }, []);

  // 카카오
  const code = new URL(window.location.href).searchParams.get("code");

  // 구글
  const accessToken = new URLSearchParams(window.location.hash.substr(1)).get(
    "access_token"
  );

  const kakaoAPI = process.env.REACT_APP_KAKAO_REST_API;

  // const redirectUri = "http://localhost:3000";

  const redirectUri = "https://www.fliption.ai";

  const kakaoLogin = () => {
    setIsLoginLoading(true);
    axios
      .post(
        `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${kakaoAPI}&redirect_uri=${redirectUri}&code=${code}`,
        {},
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((res) => {
        // console.log("토큰: ", res);
        const { data } = res;
        const { access_token, refresh_token } = data;
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("refresh_token", refresh_token);
        if (access_token) {
          const params = {
            sns_type: "kakao",
            access_token,
          };
          axios
            .post(
              "https://menjm21qci.execute-api.ap-northeast-2.amazonaws.com/prod",
              params
            )
            .then(async (res) => {
              // console.log(res);
              if (res.data.body.message === "google") {
                navigate("/");
                alert(
                  "구글 연동으로 가입된 동일한 이메일 계정이 있습니다. 다른 계정으로 로그인 해주세요."
                );
                sessionStorage.removeItem("access_token");
                dispatch(clearAuth());
                setIsLoginLoading(false);
                return;
              } else if (res.data.body.message === "naver") {
                navigate("/");
                alert(
                  "네이버 연동으로 가입된 동일한 이메일 계정이 있습니다. 다른 계정으로 로그인 해주세요."
                );
                sessionStorage.removeItem("access_token");
                dispatch(clearAuth());
                setIsLoginLoading(false);
                return;
              } else {
                // const user = res.data.body;
                // await Auth.signIn(user.username, user.password);
                // // setUsername(user.username);
                // dispatch(setId({ id: user.username }));
                // // console.log("Current user:", Auth.currentAuthenticatedUser());
                // setIsLoginLoading(false);
                // navigate("/");
                try {
                  const user = res.data.body;
                  await Auth.signIn(user.username, user.password);
                  dispatch(setId({ id: user.username }));
                  setIsLoginLoading(false);
                  navigate("/");
                } catch (error) {
                  console.log(error);
                  alert("로그인에 실패했습니다. 다시 시도해주세요.");
                  sessionStorage.removeItem("access_token");
                  dispatch(clearAuth());
                  setIsLoginLoading(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("access_token 없음");
          alert("로그인에 실패했습니다. 다시 시도해주세요.");
          sessionStorage.removeItem("access_token");
          dispatch(clearAuth());
          setIsLoginLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("로그인에 실패했습니다. 다시 시도해주세요.");
        sessionStorage.removeItem("access_token");
        dispatch(clearAuth());
        setIsLoginLoading(false);
      });
  };

  const googleTokenReset = () => {
    const accessToken = window.sessionStorage.getItem("access_token");
    axios
      .post(
        `https://oauth2.googleapis.com/revoke?token=${accessToken}`,
        {},
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((res) => {
        // console.log("구글 탈퇴 성공", res);
        sessionStorage.removeItem("access_token");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const googleLogin = () => {
    setIsLoginLoading(true);
    sessionStorage.setItem("access_token", accessToken!);
    const params = {
      sns_type: "google",
      access_token: accessToken,
    };
    // console.log(params);
    axios
      .post(
        "https://menjm21qci.execute-api.ap-northeast-2.amazonaws.com/prod",
        params
      )
      .then(async (res) => {
        // console.log(res);
        if (res.data.body.message === "kakao") {
          navigate("/");
          alert(
            "카카오 연동으로 가입된 동일한 이메일 계정이 있습니다. 다른 계정으로 로그인 해주세요."
          );
          googleTokenReset();
          sessionStorage.removeItem("access_token");
          dispatch(clearAuth());
          setIsLoginLoading(false);
          return;
        } else if (res.data.body.message === "naver") {
          navigate("/");
          alert(
            "네이버 연동으로 가입된 동일한 이메일 계정이 있습니다. 다른 계정으로 로그인 해주세요."
          );
          googleTokenReset();
          sessionStorage.removeItem("access_token");
          dispatch(clearAuth());
          setIsLoginLoading(false);
          return;
        } else {
          // const user = res.data.body;
          // await Auth.signIn(user.username, user.password);
          // dispatch(setId({ id: user.username }));
          // setIsLoginLoading(false);
          // navigate("/");
          try {
            const user = res.data.body;
            await Auth.signIn(user.username, user.password);
            dispatch(setId({ id: user.username }));
            setIsLoginLoading(false);
            navigate("/");
          } catch (error) {
            console.log(error);
            alert("로그인에 실패했습니다. 다시 시도해주세요.");
            sessionStorage.removeItem("access_token");
            dispatch(clearAuth());
            setIsLoginLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert("로그인에 실패했습니다. 다시 시도해주세요.");
        sessionStorage.removeItem("access_token");
        dispatch(clearAuth());
        setIsLoginLoading(false);
      });
  };

  useEffect(() => {
    if (code || accessToken) {
      switch (user.sns) {
        case "kakao":
          return kakaoLogin();
        case "google":
          return googleLogin();
        default:
          return;
      }
    }
  }, [code, accessToken]);

  useEffect(() => {
    const fetchData = async () => {
      if (user.name !== null) {
        return;
      }

      const userState = userData?.UserByEmail?.items[0];
      if (!userState?.name) {
        dispatch(setId({ id: userData?.UserByEmail?.items[0]?.id! }));
        navigate("/signup");
        return;
      }

      const result: loginResultType = {
        authenticated: true,
        sns: userState.snsType,
        id: userState.id,
        name: userState.name,
        needs: userState.needs,
      };
      if (userState.type === "개인") {
        result.type = "개인";
        result.age = userState.age;
        result.gender = userState.gender;
      } else {
        result.type = "기업";
        result.industryGroup = userState.industryGroup;
        result.title = userState.title;
      }
      dispatch(setAuth(result));
      mutate({
        input: {
          id: userState.id,
          source: utmSourceText ? utmSourceText : "direct",
        },
      });
    };
    userData?.UserByEmail && fetchData();
  }, [userData]);

  return (
    <>
      <Helmet>
        <title>Face Flip | Home</title>
      </Helmet>
      {isLoginLoading ? (
        <Loading>
          로그인 중입니다
          <br />
          잠시만 기다려주세요
        </Loading>
      ) : (
        <>
          <MainContainer>
            <MainBanner>
              <Marquee speed={100}>
                {[...Array(10)].map((_, index) => (
                  <img key={index} src={free} alt="banner" />
                ))}
              </Marquee>
              <span>
                무료 체험 기간 동안 사진을 자유롭게 변환하세요
                <br /> (~8/25)
              </span>
            </MainBanner>
            <MainBox>
              {user.authenticated ? (
                user.type === "기업" ? (
                  <MainBrandTitle />
                ) : (
                  <MainModellTitle />
                )
              ) : mainType === "company" ? (
                <MainBrandTitle />
              ) : (
                <MainModellTitle />
              )}
            </MainBox>
            <MainFliptionInfo>
              업체명: (주)플립션코리아 ㅣ 사업자등록번호: 4648802404 ㅣ 대표자:
              정훈진
            </MainFliptionInfo>
          </MainContainer>
        </>
      )}
    </>
  );
};

export default Main;
