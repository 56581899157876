import { Dialog, DialogContent, Grow } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { Link } from "react-router-dom";
import {
  IntroInner,
  IntroLinkBox,
  IntroText,
  IntroTextBox,
  IntroTitle,
} from "src/styles/Request";
import insta from "src/assets/images/link_insta.png";
import blog from "src/assets/images/link_blog.png";
import youtube from "src/assets/images/link_youtube.png";
import { LoginClose } from "src/styles/Login";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet-async";

interface FliptionLinkProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

const FliptionLink = (props: FliptionLinkProps) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  const user = useSelector((state: RootState) => state.user);

  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  const tagManagerArgs = (item: string) => {
    return {
      dataLayer: {
        event: "click_sns",
        source: utmSource ? utmSource : "direct",
        page_title: htmlTitle?.innerText,
        sns_category: item,
        ...(user.type === "기업"
          ? {
              brand_name: user.name,
              brand_industry: user.industryGroup,
              brand_position: user.title,
              brand_needs: user.needs,
            }
          : {
              model_age: user.age,
              model_gender: user.gender,
              model_needs: user.needs,
            }),
      },
    };
  };

  return (
    <>
      <Helmet>
        <title>Face Flip | Fliption SNS</title>
      </Helmet>
      <Dialog
        onClose={handleClose}
        open={props.open}
        TransitionComponent={Transition}
      >
        <IntroInner>
          <LoginClose onClick={handleClose} />
          <IntroTextBox>
            <IntroTitle>플립션 SNS 이동</IntroTitle>
            <IntroText>
              각 채널에서 플립션에 대한
              <br />
              자세한 이야기를
              <br className="break-m" /> 확인할 수 있습니다!
            </IntroText>
          </IntroTextBox>
          <IntroLinkBox>
            <img
              id="click_sns"
              src={insta}
              alt="insta"
              onClick={() => {
                TagManager.dataLayer(tagManagerArgs("instagram"));
                window.open(
                  "https://www.instagram.com/fliption_korea",
                  "_blank"
                );
                handleClose();
              }}
            />
            <img
              id="click_sns"
              src={blog}
              alt="blog"
              onClick={() => {
                TagManager.dataLayer(tagManagerArgs("blog"));
                window.open("https://blog.naver.com/fliption", "_blank");
                handleClose();
              }}
            />
            <img
              id="click_sns"
              src={youtube}
              alt="youtube"
              onClick={() => {
                TagManager.dataLayer(tagManagerArgs("youtube"));
                window.open(
                  "https://www.youtube.com/@fliption_official",
                  "_blank"
                );
                handleClose();
              }}
            />
          </IntroLinkBox>
        </IntroInner>
      </Dialog>
    </>
  );
};

export default FliptionLink;
