import React, { useEffect, useState } from "react";
import {
  LoadingBox,
  LoadingIcon,
  LoadingText,
  LoadingTextBox,
  LoadingTitle,
} from "../../styles/Success";

const ChangeLoading = () => {
  const [progress, setProgress] = useState(0);

  const increaseProgress = () => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 100) {
          clearInterval(intervalId);
        }
        return newProgress;
      });
    }, 50);
  };

  useEffect(() => {
    increaseProgress();
    return () => {
      setProgress(0);
    };
  }, []);

  return (
    <LoadingBox>
      <LoadingIcon progress={progress} />
      <LoadingTextBox>
        <LoadingTitle>Loading ...</LoadingTitle>
        <LoadingText>
          화질에 따라 최대 10초가 소요됩니다.
          <br />
          화면을 나가지 말고 기다려 주세요!
        </LoadingText>
      </LoadingTextBox>
    </LoadingBox>
  );
};

export default ChangeLoading;
