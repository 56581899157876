import React, { useEffect, useState } from "react";
import {
  MyPageContainer,
  MyPageInner,
  MyPageSubTitle,
  MyPageTitle,
  WithdrawalText,
} from "../../styles/User";
import { useNavigate } from "react-router-dom";
import NoImage from "./NoImage";
import { Button } from "@mui/material";
import Library from "./Library";
import { ModelSortDirection, useVContentByUserQuery } from "src/lib/api";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { Storage } from "aws-amplify";
import Loading from "src/components/Loading";
import LibraryLoading from "./LibraryLoading";
import TagManager from "react-gtm-module";

const Mypage = () => {
  const navigate = useNavigate();
  const [contentsLoading, setContentsLoading] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  // gtm event
  const htmlTitle = document.querySelector("title");
  const utmSource = sessionStorage.getItem("utm_source");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSource ? utmSource : "direct",
      },
    });
  }, []);

  // 변환본 불러오기
  const { data, isLoading } = useVContentByUserQuery({
    userID: user.id!,
    limit: 10,
    sortDirection: ModelSortDirection.Desc,
  });

  const [contents, setContents] = useState<
    (
      | {
          src: string;
          blob: Blob;
          name: string;
          selected: boolean;
        }
      | undefined
    )[]
  >([]);

  const getImages = async () => {
    const items = data?.VContentByUser?.items;
    if (items) {
      setContentsLoading(true);
      const results = await Promise.all(
        items.map(async (item) => {
          const imgKey = item?.storage;
          if (imgKey) {
            const img = await Storage.get(imgKey);
            const blob = await fetch(img).then((r) => r.blob());
            return {
              src: img,
              blob: blob,
              name: item?.fileName,
              selected: false,
            };
          }
        })
      );
      setContentsLoading(false);
      setContents(results);
    }
  };

  useEffect(() => {
    getImages();
  }, [data]);

  // console.log(contents);

  if (isLoading) {
    return <Loading>로딩 중입니다</Loading>;
  }
  return (
    <MyPageContainer>
      <MyPageInner>
        <MyPageTitle>라이브러리</MyPageTitle>
        <MyPageSubTitle>최근 변환한 10장의 사진이 제공됩니다!</MyPageSubTitle>
        {data?.VContentByUser?.items.length !== 0 ? (
          contentsLoading ? (
            <LibraryLoading />
          ) : (
            <Library contents={contents} setContents={setContents} />
          )
        ) : (
          <NoImage />
        )}
      </MyPageInner>
      <WithdrawalText>
        <span
          onClick={() => {
            navigate("/mypage/withdrawal");
          }}
        >
          탈퇴하기
        </span>
      </WithdrawalText>
    </MyPageContainer>
  );
};

export default Mypage;
