import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  ChangeFace,
  Main,
  Request,
  Mypage,
  Withdrawal,
  SNSLogin,
  Result,
  SignUp,
} from "src/pages";
import PrivateRoute from "./PrivateRoute";

const SwitchPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/change" element={<ChangeFace />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/oauth" element={<SNSLogin />} />
      <Route path="/mypage/withdrawal" element={<Withdrawal />} />
      <Route element={<PrivateRoute />}>
        <Route path="/result" element={<Result />} />
        <Route path="/request" element={<Request />} />
        <Route path="/mypage" element={<Mypage />} />
      </Route>
      <Route path="/*" element={<Main />} />
    </Routes>
  );
};

export default SwitchPage;
