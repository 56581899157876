import { combineReducers, configureStore } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/lib/storage/session";
import userReducer from "../store/slice/userSlice";
import resultReducer from "../store/slice/resultSlice";
import pageReducer from "../store/slice/pageSlice";
import { persistReducer, persistStore } from "redux-persist";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const reducers = combineReducers({
  user: userReducer,
  result: resultReducer,
  page: pageReducer,
});

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: ["user", "result", "page"],
};

const presistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: presistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export const persistor = persistStore(store);
