import styled from "styled-components";
import mainBg2 from "src/assets/images/main_bg2.png";

// - 메인 선택 페이지
export const MainSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #12100f;
  padding: 5%;
  > img {
    position: absolute;
    top: 53px;
    left: 53px;
  }
  ${(props) => props.theme.media.tablet`
      >img {
        width: 200px;
      }
  `}
  ${(props) => props.theme.media.mobile`
    >img {
      top: 30px;
      left: 30px;
      width: 140px;
    }
  `}
`;
export const MainSelectContent = styled.div`
  ${({ theme }) => theme.flexCol}
  gap: 66px;
  width: 100%;
  ${(props) => props.theme.media.mobile`
  gap: 50px;
  `}
`;
export const MainSelectTitle = styled.h2`
  font-size: 60px;
  font-weight: 800;
  line-height: 78px;
  text-align: center;
  color: #fff;
  ${(props) => props.theme.media.tablet`
  font-size: 48px;
  line-height: 72px;
  `}
  ${(props) => props.theme.media.mobile`
  font-size: 35px;
  line-height: 50px;
  `}
  ${(props) => props.theme.media.small`
  font-size: 30px;
  line-height: 50px;
  `}
  @media screen and (max-width: 400px) {
    font-size: 27px;
  }
`;
export const MainSelectBtBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 176.5px;
  width: 100%;
  ${(props) => props.theme.media.tablet`
  gap: 100px;
  `}
  ${(props) => props.theme.media.mobile`
  gap: 35px;
  `}
`;

// - 메인페이지
export const MainContainer = styled.article`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 95vh;
  background: #12100f url(${mainBg2}) no-repeat;
  background-size: 60%;
  background-position: right -150px bottom -100px;
  overflow: hidden;
  @media screen and (max-width: 1450px) {
    background-size: 1053px 810px;
  }
  ${(props) => props.theme.media.tablet`
  background-size: 100%;
  background-position: center bottom;
  `}
  ${(props) => props.theme.media.mobile`
  // min-height: 96vh;
  background-size: 130%;
  background-position: 50% bottom;
  `}
`;
export const MainBanner = styled.div`
  position: relative;
  height: 100px;
  background-color: #464646 !important;
  overflow: hidden;
  br {
    display: none;
  }
  img {
    height: 100px;
    /* margin-right: 20px; */
  }
  span {
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 822.29px / 2);
    padding: 0 15px;
    font-size: 35px;
    font-weight: 800;
    line-height: 46px;
    color: #fff;
    text-align: center;
    z-index: 1;
    word-break: keep-all;
  }
  @media (prefers-color-scheme: dark) {
    background-color: #464646 !important;
  }
  ${(props) => props.theme.media.tablet`
  height: 75px;
  img {
    height: 75px;
  }
  span {
    top: calc(50% - 20px);
    left: calc(50% - 709.84px / 2);
    font-size: 30px;
    line-height: 40px;
  }
    `}
  ${(props) => props.theme.media.mobile`
  height: 50px;
  img {
    height: 75px;
    height: 50px;
    // margin-right: 15px;
  }
    span {
      top: calc(50% - 12.5px);
      left: calc(50% - 417.45px / 2);
      font-size: 17px;
      line-height: 25px;
    }
  `}
  @media screen and (max-width: 430px) {
    br {
      display: block;
    }
    span {
      top: calc(50% - 20px);
      left: calc(50% - 275.7px / 2);
      padding: 0 5px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
export const MainBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 62px;
  padding: 225px 113px;
  z-index: 1;
  @media screen and (max-width: 1450px) {
    padding: 15% 50px;
  }
  ${(props) => props.theme.media.tablet`
    gap: 25px;
    padding: 8% 7%;
  `}
  ${(props) => props.theme.media.mobile`
    gap: 15px;
  `}
  @media screen and (max-width: 400px) {
    padding: 10% 7% 23%;
  }
`;
export const MainImg2 = styled.img`
  position: absolute;
  bottom: -10%;
  left: 39%;
  z-index: 1;
  ${(props) => props.theme.media.tablet`
    display: none;
  `}
`;
// 메인페이지 타이틀
export const MainTitle = styled.h1`
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 59px;
  line-height: 77px;
  align-items: center;
  color: #fff;
  word-break: keep-all;
  @media screen and (max-width: 1750px) {
    /* text-shadow: 1px 0 #000, 0 1px #000, -1px 0 #000, 0 -1px #000; */
  }
  @media screen and (max-width: 1300px) {
    font-size: 55px;
  }
  ${(props) => props.theme.media.tablet`
    margin-bottom: 13px;
    font-size: 41px;
    line-height: 50px;
  `}
  @media screen and (max-width: 800px) {
    font-size: 35px;
    line-height: 43px;
  }
  @media screen and (max-width: 675px) {
    font-size: 31px;
    line-height: 38px;
  }
  @media screen and (max-width: 400px) {
    margin-bottom: 7px;
    font-size: 25px;
    line-height: 32px;
  }
`;
export const MainSubTitle = styled.h2`
  font-weight: 400;
  font-size: 27px;
  line-height: 37px;
  align-items: center;
  color: #fff;
  word-break: keep-all;
  .break-word {
    display: none;
  }
  span.break-m {
    display: block;
  }
  @media screen and (max-width: 1750px) {
    /* text-shadow: 1px 0 #000, 0 1px #000, -1px 0 #000, 0 -1px #000; */
  }
  @media screen and (max-width: 1150px) {
    font-size: 22px;
    /* text-shadow: 2px 2px 2px gray; */
  }
  ${(props) => props.theme.media.tablet`
    font-size: 20px;
    line-height: 30px;
    span {
      background: #000;
    }
`}
  ${(props) => props.theme.media.mobile`
    font-size: 13px;
    line-height: 18px;
    span.break-m {
      display: none;
    }
`}
@media screen and (max-width: 610px) {
    .break-word {
      display: block;
    }
  }
  @media screen and (max-width: 400px) {
    font-size: 10px;
    line-height: 16px;
  }
`;
export const MainFliptionInfo = styled.span`
  position: absolute;
  bottom: 7px;
  right: 19px;
  font-size: 15px;
  line-height: 20px;
  color: #b9b9b9;
  word-break: keep-all;
  text-shadow: 0.5px 0 #313131, 0 0.5px #313131, -0.5px 0 #313131,
    0 -0.5px #313131;
  ${(props) => props.theme.media.tablet`
  font-size: 12px;
  `}
  ${(props) => props.theme.media.mobile`
  right: 0;
  width: 100%;
  padding: 0 7px;
  font-size: 10px;
  text-align: center;
`}
`;
