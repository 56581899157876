import React from "react";
import { FaceCategoryBt, SelectButton } from "src/styles/Button";
import { SelectFace1List } from "src/styles/ChangeFace";

interface SelectFace1Props {
  step1Select: {
    id: number;
    gender: string;
    nationality: string;
    face_category: string;
  } | null;
  setStep1Select: React.Dispatch<
    React.SetStateAction<{
      id: number;
      gender: string;
      nationality: string;
      face_category: string;
    } | null>
  >;
  setStep: (step: number) => void;
  setSelectVFace: (selectVFace: string) => void;
  getImages: () => void;
}

const SelectFace1Box = (props: SelectFace1Props) => {
  const faceCategories = [
    {
      id: 1,
      gender: "female",
      nationality: "asian",
      face_category: "female_asian_kind",
      category: "동양여_선하고 착한",
      label: "선하고 착한",
    },
    {
      id: 4,
      gender: "female",
      nationality: "western",
      face_category: "female_western_kind",
      category: "서양여_부드럽고 온화한",
      label: "부드럽고 온화한",
    },
    {
      id: 7,
      gender: "male",
      nationality: "asian",
      face_category: "male_asian_kind",
      category: "동양남_선하고 착한",
      label: "선하고 착한",
    },
    {
      id: 10,
      gender: "male",
      nationality: "western",
      face_category: "male_western_kind",
      category: "서양남_부드럽고 온화한",
      label: "부드럽고 온화한",
    },
    {
      id: 2,
      gender: "female",
      nationality: "asian",
      face_category: "female_asian_storng",
      category: "동양여_날카롭고 센",
      label: "날카롭고 센",
    },
    {
      id: 5,
      gender: "female",
      nationality: "western",
      face_category: "female_western_strong",
      category: "서양여_진하고 강한",
      label: "진하고 강한",
    },
    {
      id: 8,
      gender: "male",
      nationality: "asian",
      face_category: "male_asian_storng",
      category: "동양남_날카롭고 센",
      label: "날카롭고 센",
    },
    {
      id: 11,
      gender: "male",
      nationality: "western",
      face_category: "male_western_strong",
      category: "서양남_진하고 강한",
      label: "진하고 강한",
    },
    {
      id: 3,
      gender: "female",
      nationality: "asian",
      face_category: "female_asian_cute",
      category: "동양여_귀엽고 동그란",
      label: "귀엽고 동그란",
    },
    {
      id: 6,
      gender: "female",
      nationality: "western",
      face_category: "female_western_mixedrace",
      category: "서양여_혼혈",
      label: "혼혈 느낌이 나는",
    },
    {
      id: 9,
      gender: "male",
      nationality: "asian",
      face_category: "male_asian_cute",
      category: "동양남_귀엽고 동그란",
      label: "귀엽고 동그란",
    },
    {
      id: 12,
      gender: "male",
      nationality: "western",
      face_category: "male_western_mixedrace",
      category: "서양남_혼혈",
      label: "혼혈 느낌이 나는",
    },
  ];

  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  return (
    <SelectFace1List>
      <div>
        <span>여자</span>
      </div>
      <div>
        <span>남자</span>
      </div>
      <div>
        <span>동양인</span>
      </div>
      <div>
        <span>서양인</span>
      </div>
      <div>
        <span>동양인</span>
      </div>
      <div>
        <span>서양인</span>
      </div>
      {faceCategories.map((category) => (
        <div>
          <FaceCategoryBt
            key={category.id}
            selected={
              props.step1Select && props.step1Select.id === category.id
                ? "orange"
                : "grey"
            }
            onClick={() => {
              props.setStep1Select({
                id: category.id,
                gender: category.gender,
                nationality: category.nationality,
                face_category: category.face_category,
              });
              props.setSelectVFace(category.category);
            }}
          >
            {category.label}
          </FaceCategoryBt>
        </div>
      ))}
      {props.step1Select !== null && (
        <SelectButton
          btcolor="orange"
          onClick={async () => {
            await props.getImages();
            props.setStep(1);
            // scrollToTop();
          }}
        >
          다음
        </SelectButton>
      )}
    </SelectFace1List>
  );
};

export default SelectFace1Box;
