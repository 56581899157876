import React from "react";
import {
  RequestFormBox,
  RequestFormTitle,
  RequestInput,
  RequestInputBox,
  RequestInputClose,
  RequestInputInner,
  RequestInputLabel,
  RequestInputList,
  RequestInputTitle,
  RequestListItem,
} from "../../styles/Request";
import { SubmitHandler, useForm } from "react-hook-form";
import { MiddleBtn } from "../../styles/Button";
import { Helmet } from "react-helmet-async";
import { useCreateApplicationFormMutation, useGetUserQuery } from "src/lib/api";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useLocation, useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

interface FormValuesType {
  name: string;
  phone: string;
  email: string;
  content: string;
}

interface RequestFormProps {
  setDone: (value: boolean) => void;
}

const RequestForm = (props: RequestFormProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValuesType>();

  const user = useSelector((state: RootState) => state.user);

  const { data: userData } = useGetUserQuery({ id: user.id! });

  const { mutate } = useCreateApplicationFormMutation();

  // 태그매니저 events
  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  const brandArgs = {
    dataLayer: {
      event: "request_info",
      page_title: htmlTitle?.innerText,
      source: utmSource ? utmSource : "direct",
      brand_name: user.name,
      brand_industry: user.industryGroup,
      brand_position: user.title,
      brand_needs: user.needs,
    },
  };

  const onSubmit: SubmitHandler<FormValuesType> = (submit) => {
    // console.log(submit);
    // console.log(submit.phone.replace(/-/g, ""));
    const userInput = {
      id: userData?.getUser?.id!,
      email: userData?.getUser?.email!,
      snsType: userData?.getUser?.snsType!,
      type: userData?.getUser?.type!,
      name: userData?.getUser?.name!,
      age: userData?.getUser?.age!,
      gender: userData?.getUser?.gender!,
      industryGroup: userData?.getUser?.industryGroup!,
      title: userData?.getUser?.title!,
      needs: userData?.getUser?.needs!,
    };
    mutate({
      input: {
        userID: user.id!,
        user: userInput,
        email: submit.email,
        name: submit.name,
        phoneNumber: submit.phone.replace(/-/g, ""),
        request: submit.content,
      },
    });
    props.setDone(true);
  };

  return (
    <RequestFormBox onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Face Flip | IR request</title>
      </Helmet>
      <RequestFormTitle>무료 테스트 신청</RequestFormTitle>
      <RequestInputInner>
        <RequestInputTitle>
          인적사항을 남겨주시면
          <br className="break-m" /> 이메일로 소개서를 보내드립니다!
        </RequestInputTitle>
        <RequestInputList>
          <RequestInputClose
            onClick={() => {
              if (window.confirm("신청을 취소하시겠습니까?")) {
                navigate(-1);
              }
            }}
          />
          <RequestListItem>
            <RequestInputBox>
              <RequestInputLabel>성함</RequestInputLabel>
              <RequestInput
                type="text"
                placeholder=" (칸을 클릭하여 내용을 입력해주세요) "
                {...register("name", {
                  required: "성함을 입력해주세요",
                  minLength: {
                    value: 2,
                    message: "2글자 이상 입력해주세요",
                  },
                  pattern: {
                    value: /^[A-za-z0-9가-힣]{2,10}$/,
                    message:
                      "2 ~ 10글자 사이의 한글, 영문 대소문자, 숫자를 입력해주세요",
                  },
                })}
              />
            </RequestInputBox>
            {errors.name && <p>{errors.name.message}</p>}
          </RequestListItem>
          <RequestListItem>
            <RequestInputBox>
              <RequestInputLabel>연락처</RequestInputLabel>
              <RequestInput
                type="text"
                placeholder=" (칸을 클릭하여 내용을 입력해주세요) "
                {...register("phone", {
                  required: "전화번호를 입력해주세요",
                  pattern: {
                    value:
                      /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
                    message: "유효한 전화번호 형식이 아닙니다",
                  },
                })}
              />
            </RequestInputBox>
            {errors.phone && <p>{errors.phone.message}</p>}
          </RequestListItem>
          <RequestListItem>
            <RequestInputBox>
              <RequestInputLabel>이메일</RequestInputLabel>
              <RequestInput
                type="text"
                placeholder=" (칸을 클릭하여 내용을 입력해주세요) "
                {...register("email", {
                  required: "이메일을 입력해주세요",
                  pattern: {
                    value:
                      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                    message: "유효한 이메일 형식이 아닙니다",
                  },
                })}
              />
            </RequestInputBox>
            {errors.email && <p>{errors.email.message}</p>}
          </RequestListItem>
          <RequestListItem>
            <RequestInputBox>
              <RequestInputLabel>요청사항</RequestInputLabel>
              <RequestInput
                type="text"
                placeholder=" (요청하실 내용을 남겨주세요) "
                {...register("content", {
                  required: "요청사항을 입력해주세요",
                  minLength: {
                    value: 2,
                    message: "2글자 이상 입력해주세요",
                  },
                })}
              />
            </RequestInputBox>
            {errors.content && <p>{errors.content.message}</p>}
          </RequestListItem>
        </RequestInputList>
      </RequestInputInner>
      <MiddleBtn
        id="request_info"
        btcolor="orange"
        type="submit"
        onClick={() => {
          if (
            !errors.name &&
            !errors.phone &&
            !errors.email &&
            !errors.content
          ) {
            TagManager.dataLayer(brandArgs);
          }
        }}
      >
        신청 완료
      </MiddleBtn>
    </RequestFormBox>
  );
};

export default RequestForm;
