import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import logo from "src/assets/images/Fliption_logo_1.png";
import { MainSelectBt } from "src/styles/Button";
import {
  MainSelectBtBox,
  MainSelectContainer,
  MainSelectContent,
  MainSelectTitle,
} from "src/styles/Main";
import { setMainType } from "src/store/slice/userSlice";

const MainSelect = () => {
  const dispatch = useDispatch();

  // utm source
  const utmSource = new URLSearchParams(window.location.search).get(
    "utm_source"
  );

  const typeCheck = (check: string) => {
    dispatch(setMainType(check));
  };

  useEffect(() => {
    if (utmSource) {
      sessionStorage.setItem("utmSource", utmSource);
    }
  }, [utmSource]);

  return (
    <MainSelectContainer>
      <img src={logo} alt="logo" />
      <MainSelectContent>
        <MainSelectTitle>
          체험에 앞서
          <br className="break" /> 해당되는 곳에 체크해주세요 :)
        </MainSelectTitle>
        <MainSelectBtBox>
          <MainSelectBt
            btcolor="grey"
            onClick={() => {
              typeCheck("personal");
            }}
          >
            개인 / 모델
          </MainSelectBt>
          <MainSelectBt
            btcolor="grey"
            onClick={() => {
              typeCheck("company");
            }}
          >
            기업 / 브랜드
          </MainSelectBt>
        </MainSelectBtBox>
      </MainSelectContent>
    </MainSelectContainer>
  );
};

export default MainSelect;
