import styled, { css } from "styled-components";

export const FlexColBox = styled.div`
  ${({ theme }) => theme.flexCol}
  justify-content: center;
  height: 100%;
`;
export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 145px;
  background: #12100f;
  img {
    width: 247px;
    height: 52px;
    margin-left: 53px;
    cursor: pointer;
  }
  ${(props) => props.theme.media.tablet`
    height: 100px;
    align-items: center;
    padding: 0 10px;
    img {
      width: 150px;
      height: 32px;
      margin-top: 0;
      margin-left: 20px;
    }
`}
  ${(props) => props.theme.media.mobile`
    // height: 7vh;
    height: 50px;
    img {
      width: 120px;
      height: 26px;
      margin-left: 10px;
    }
`}
  @media screen and (max-width: 400px) {
    img {
      max-width: 90px;
      height: 20px;
    }
  }
`;
export const HeaderButtonBox = styled.ul`
  display: flex;
  align-items: center;
  gap: 41px;
  margin-right: 27px;
  ${(props) => props.theme.media.tablet`
    gap: 15px;
    margin-right: 10px;
`}
`;

// 모바일 메뉴
export const MobileIcon = styled.div<{ open: boolean }>`
  position: relative;
  display: block;
  border: none;
  background: transparent;
  font-size: 0;
  width: 40px;
  height: 35px;
  padding: 10px;
  /* margin-top: -10px; */
  z-index: 1300;
  cursor: pointer;
  & i {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  & i::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #b3b3b3;
    transform-origin: 0% 50%;
    transition: all 0.3s;
  }
  & i::after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    display: block;
    width: 100%;
    height: 2px;
    background: #b3b3b3;
    transition: width 0.3s;
    transform-origin: 0% 50%;
    transition: all 0.3s;
  }
  & i span {
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background: #b3b3b3;
    opacity: 1;
    transition: opacity 0.3s;
  }
  ${(props) =>
    props.open &&
    css`
      & i::before {
        left: 3px !important;
        top: 0 !important;
        transform: rotate(45deg) !important;
      }
      & i::after {
        right: -3px !important;
        top: calc(100% - 1px) !important;
        width: 100% !important;
        transform: rotate(-45deg) !important;
      }
      & i span {
        opacity: 0 !important;
      }
    `}
`;
export const MobileInner = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  ${(props) => props.theme.media.mobile`
  padding-top: 50px;
  `}
`;
export const MobileMenuText = styled.li<{ color: string }>`
  margin-bottom: 8px;
  padding: 12px 40px;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: ${(props) => (props.color === "grey" ? "#9f9f9f" : "#ff6410")};
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
  @media screen and (max-width: 400px) {
    font-size: 22px;
    padding: 10px 35px;
  }
`;
