import styled from "styled-components";
import mainBg from "src/assets/images/main_bg.png";
import left from "src/assets/images/scroll_left.png";
import right from "src/assets/images/scroll_right.png";

// 마이페이지
export const MyPageContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 935px; */
  min-height: 95vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${mainBg});
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
  }
  ${(props) => props.theme.media.mobile`
  // min-height: 96vh;
  `}
`;
export const MyPageInner = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  width: 100%;
  height: 100%;
  padding: 61px 0 85px;
  ${(props) => props.theme.media.tablet`
  padding-bottom: 60px;
  `}
  ${(props) => props.theme.media.mobile`
  padding: 10% 0 60px;
  `}
`;
export const MyPageTitle = styled.p`
  ${({ theme }) => theme.text_62px}
  text-align: center;
  color: #fff;
`;
export const MyPageSubTitle = styled.p`
  margin-bottom: 50px;
  font-size: 34px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  word-break: keep-all;
  padding: 0 5%;
  ${(props) => props.theme.media.tablet`
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 38px;
  `}
  ${(props) => props.theme.media.small`
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 28px;
`}
`;
export const NoImageBox = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  justify-content: center;
  width: 81%;
  height: 100%;
  min-height: 626px;
  padding: 45px 0;
  background: rgba(0, 0, 0, 0.6);
  border: 7px solid #adadad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  ${(props) => props.theme.media.tablet`
  min-height: 550px;
  padding: 5%;
  `}
  ${(props) => props.theme.media.small`
  min-height: 520px;
  `}
`;
export const NoImageImg = styled.img`
  width: 100%;
  max-width: 200px;
  object-fit: contain;
  ${(props) => props.theme.media.small`
  max-width: 150px;
  `}
`;
export const NoImageText = styled.p`
  font-size: 34px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  margin-bottom: 58px;
  word-break: keep-all;
  ${(props) => props.theme.media.tablet`
  font-size: 28px;
  line-height: 38px;
  `}
  ${(props) => props.theme.media.small`
  font-size: 20px;
  line-height: 30px;
`}
`;
export const LibraryBox = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  /* justify-content: center; */
  width: 81%;
  height: 626px;
  padding: 45px 0;
  background: rgba(0, 0, 0, 0.6);
  border: 7px solid #adadad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  ${(props) => props.theme.media.mobile`
  height: 550px;
  `}
  ${(props) => props.theme.media.small`
  height: 520px;
`}
`;
export const LibraryImgBox = styled.div`
  width: 100%;
  height: 100%;
  div {
    display: flex;
    width: 100%;
    gap: 35px;
    padding: 0 32px 130px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 38px;
    }
    &::-webkit-scrollbar-track {
      background: #adadad;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ff6510;
      box-shadow: 3px 4px 4px 0px #00000040;
    }
    &::-webkit-scrollbar-button:start:decrement {
      display: block;
      width: 43px;
      height: 38px;
      background: #777 url(${left}) no-repeat;
      background-size: 25px;
      background-position: center left 5px;
    }
    &::-webkit-scrollbar-button:end:increment {
      display: block;
      width: 43px;
      height: 38px;
      background: #777 url(${right}) no-repeat;
      background-size: 25px;
      background-position: center right 5px;
    }
  }
  ${(props) => props.theme.media.mobile`
  div{
    gap: 15px;
    padding: 0 15px 100px;
    &::-webkit-scrollbar {
      height: 25px;
    }
    &::-webkit-scrollbar-button:start:decrement {
      display: block;
      width: 25px;
      height: 25px;
      background-size: 50%;
    }
    &::-webkit-scrollbar-button:end:increment {
      display: block;
      width: 25px;
      height: 25px;
      background-size: 50%;
      background-position: 9px;
    }
  }
`}
`;
export const LibraryImg = styled.img<{ selected: string }>`
  width: 244px;
  height: 366px;
  border-radius: 28px;
  border: 7px solid;
  margin: 0 auto;
  /* object-fit: cover; */
  background: #000;
  object-fit: scale-down;
  border-color: ${(props) =>
    props.selected === "orange" ? "#ff6410" : "#bcbcbc"};
  transition: border-color 0.25s ease-in-out;
  cursor: pointer;
  ${(props) => props.theme.media.mobile`
    width: 210px;
    height: 330px;
  `}
  ${(props) => props.theme.media.small`
    width: 180px;
    height: 300px;
    border: 5px solid;
    border-color: ${(props: { selected: string }) =>
      props.selected === "orange" ? "#ff6410" : "#bcbcbc"};
`}
`;
export const LibraryText = styled.p`
  position: absolute;
  bottom: 133px;
  margin-top: 50px;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  word-break: keep-all;
  padding: 0 10px;
  ${(props) => props.theme.media.tablet`
  bottom: 125px;
  `}
  ${(props) => props.theme.media.mobile`
  bottom: 80px;
  font-size: 20px;
  `}
  ${(props) => props.theme.media.small`
  bottom: 85px;
  font-size: 16px;
  line-height: 24px;
  `}
`;

// 탈퇴 버튼
export const WithdrawalText = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  width: 182px;
  height: 85px;
  font-weight: 800;
  font-size: 26px;
  span {
    color: #787878;
    transition: color 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      color: #999;
    }
  }
  ${(props) => props.theme.media.tablet`
  height: 65px;
  `}
  ${(props) => props.theme.media.mobile`
  font-size: 23px;
  width: 120px;
  height: 60px;
  `}
`;

// 탈퇴 페이지
export const WithdrawalContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 935px; */
  min-height: 95vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${mainBg});
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
  }
  ${(props) => props.theme.media.mobile`
  // min-height: 96vh;
  `}
`;
export const WithdrawalInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
  height: 100%;
  padding: 194px 5% 0;
  ${(props) => props.theme.media.tablet`
    padding-top: 25%;
  `}
`;
// 탈퇴 수락 박스
export const WithdrawalAcceptBox = styled.div`
  ${({ theme }) => theme.optionBox}
  width: 522px;
  height: 407px;
  padding: 30px;
  ${(props) => props.theme.media.tablet`
    width: 100%;
    max-width: 450px;
    height: 100%;
    padding: 60px 30px;
`}
  ${(props) => props.theme.media.mobile`
    gap: 15px;
    padding: 45px 25px;
`}
`;
// 탈퇴 수락 버튼 박스
export const WithdrawalAcceptBtnBox = styled.div`
  display: flex;
  gap: 18.48px;
  margin: 62px 10px 0 10px;
  width: 100%;
  justify-content: center;
  ${(props) => props.theme.media.tablet`
  margin: 20px 10px 0 10px;
`}
`;
// 탈퇴 사유 페이지
export const WithdrawalReasonInner = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 100%;
  padding: 118px 5% 82px 5%;
  backdrop-filter: blur(4px);
  ${(props) => props.theme.media.tablet`
  padding: 20% 5%;
  gap: 40px;
  `}
  ${(props) => props.theme.media.mobile`
  padding: 27% 5%;
  gap: 20px;
`}
`;
export const WithdrawalReasonBox = styled.div`
  ${({ theme }) => theme.optionBox}
  gap: 43px;
  width: 100%;
  height: 100%;
  max-width: 732px;
  max-height: 630px;
  padding: 48px 25px;
  ${(props) => props.theme.media.tablet`
    gap: 30px;
  `}
  ${(props) => props.theme.media.mobile`
    gap: 20px;
    padding: 35px 25px;
  `}
`;
export const WithdrawalReasonTitle = styled.h3`
  font-weight: 800;
  font-size: 50px;
  line-height: 65px;
  color: #676767;
  ${(props) => props.theme.media.tablet`
    font-size: 45px;
    line-height: 55px;
  `}
  ${(props) => props.theme.media.mobile`
    font-size: 38px;
    line-height: 45px;
  `}
  ${(props) => props.theme.media.small`
    font-size: 34px;
    line-height: 42px;
  `}
`;
export const WithdrawalReasonSubTitle = styled.p`
  font-weight: 700;
  font-size: 31px;
  line-height: 40px;
  color: #787878;
  word-break: keep-all;
  ${(props) => props.theme.media.tablet`
    font-size: 27px;
    line-height: 35px;
  `}
  ${(props) => props.theme.media.mobile`
    font-size: 24px;
    line-height: 35px;
  `}
  ${(props) => props.theme.media.small`
    font-size: 21px;
    line-height: 30px;
  `}
`;
export const WithdrawalBtBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13px;
  width: 100%;
`;
export const WithdrawalInputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 460px;
  > p {
    position: absolute;
    bottom: -27px;
    width: 100%;
    padding: 7px 5px 0 0;
    color: #e94b48;
    font-weight: 600;
    text-align: right;
  }
  ${(props) => props.theme.media.mobile`
  > p {
    font-size: 14px;
  }
  `}
`;
export const WithdrawalInput = styled.input`
  width: 100%;
  height: 72px;
  background: rgba(255, 100, 16, 0.3);
  border: 4px solid #ff6410;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  &::placeholder {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
  }
  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  ${(props) => props.theme.media.tablet`
  height: 64px;
  font-size: 24px;
  `}
  ${(props) => props.theme.media.mobile`
  height: 56px;
  font-size: 20px;
  &::placeholder {
    font-size: 20px;
  }
  `}
  ${(props) => props.theme.media.small`
  height: 48px;
  font-size: 18px;
  &::placeholder {
    font-size: 18px;
  }
  `}
  @media screen and (max-width: 400px) {
    font-size: 15px;
    &::placeholder {
      font-size: 15px;
    }
  }
`;
// 탈퇴 완료 페이지
export const WithdrawalCompleteInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
`;
