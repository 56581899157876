import React, { useRef } from "react";
import { Fade } from "react-awesome-reveal";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { MiddleBtn } from "src/styles/Button";
import {
  ChangeCautionBox,
  ChangeCautiontText,
  ChangeCautiontTitle,
} from "src/styles/ChangeFace";

interface UploadFailProps {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
}

const ChangeFail = (props: UploadFailProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const user = useSelector((state: RootState) => state.user);

  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  const swapArgs = {
    dataLayer: {
      event: "swap",
      source: utmSource ? utmSource : "direct",
      page_title: htmlTitle?.innerText,
      swap_result: props.error,
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  return (
    <Fade>
      <Helmet>
        <title>Face Flip | Swap fail</title>
      </Helmet>
      <ChangeCautionBox>
        <ChangeCautiontTitle>Oops!</ChangeCautiontTitle>
        {props.error === "fail_network" ? (
          <ChangeCautiontText>
            네트워크 오류로 인해
            <br />
            변환이 되지 않았어요 🥲
            <br />
            재시도해 주세요!
          </ChangeCautiontText>
        ) : (
          <ChangeCautiontText>
            변환이 되지 않았어요 🥲
            <br />
            업로드한 사진이 눈코입이 구별되는지
            <br />
            확인 후에 재시도해 주세요!
          </ChangeCautiontText>
        )}
        <MiddleBtn
          id="swap"
          btcolor="orange"
          onClick={() => {
            TagManager.dataLayer(swapArgs);
            handleButtonClick();
          }}
        >
          다시 올리기
        </MiddleBtn>
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          id="sideImageUpload"
          onChange={props.handleUpload}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
      </ChangeCautionBox>
    </Fade>
  );
};

export default ChangeFail;
