import { Dialog, Rating } from "@mui/material";
import React, { useState } from "react";
import {
  NoRatingBt,
  RatingContainer,
  RatingResultBox,
  RatingResultText,
  RatingResultTitle,
  RatingSubTitle,
  RatingTitle,
} from "../../styles/Success";
import { MiddleBtn, RatingButton } from "../../styles/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { Helmet } from "react-helmet-async";
import { useUpdateVContentMutation } from "src/lib/api";
import TagManager from "react-gtm-module";

interface LoginProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  change: boolean;
  src: string;
}

const ServiceRating = (props: LoginProps) => {
  const navigate = useNavigate();

  const result = useSelector((state: RootState) => state.result);
  const user = useSelector((state: RootState) => state.user);

  const [value, setValue] = useState<number | null>(null);

  const [page, setPage] = useState(0);

  const utmSource = sessionStorage.getItem("utm_source");

  // 태그매니저 events
  const tagManagerArgs = {
    dataLayer: {
      event: "rating",
      source: utmSource ? utmSource : "direct",
      rating_stars: value,
      ...(user.type === "기업"
        ? {
            brand_industry: user.industryGroup,
          }
        : {
            model_needs: user.needs,
          }),
    },
  };

  const myPageArgs = {
    dataLayer: {
      event: "mypage",
      source: utmSource ? utmSource : "direct",
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_name: user.name,
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  const handleDownload = () => {
    // if (!result.image) {
    //   return alert("변환된 이미지가 없습니다.");
    // }
    const imageUrl = result.image!;
    const fileName = result.name!;
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = fileName;
    link.click();
    setPage(1);
  };

  // 별점 업데이트
  const { mutate } = useUpdateVContentMutation();

  const handleClose = () => {
    setValue(null);
    setPage(0);
    props.setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Face Flip | Stars</title>
      </Helmet>
      <Dialog onClose={handleClose} open={props.open}>
        {/* <DialogContent> */}
        {page === 0 && (
          <RatingContainer>
            <RatingTitle>서비스 평가</RatingTitle>
            <RatingSubTitle>결과물에 대한 만족도를 남겨주세요!</RatingSubTitle>
            <Rating
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              // size="large"
            />
            {value ? (
              props.change ? (
                <RatingButton
                  btcolor="orange"
                  id="rating"
                  onClick={() => {
                    TagManager.dataLayer(tagManagerArgs);
                    mutate({
                      input: {
                        id: result.id!,
                        rating: value,
                      },
                    });
                    navigate("/change");
                  }}
                >
                  다시 변환하기
                </RatingButton>
              ) : (
                <RatingButton
                  btcolor="orange"
                  onClick={() => {
                    mutate({
                      input: {
                        id: result.id!,
                        rating: value,
                      },
                    });
                    handleDownload();
                  }}
                >
                  저장하기
                </RatingButton>
              )
            ) : (
              <NoRatingBt />
            )}
          </RatingContainer>
        )}
        {page === 1 && (
          <RatingResultBox>
            <RatingResultTitle>감사합니다!</RatingResultTitle>
            {user.type === "기업" ? (
              <RatingResultText>
                변환한 사진은 ‘마이페이지’ 에서도 확인 가능합니다.
                <br />
                <br />
                추가로 궁금하신 부분이 있다면
                <br />
                우측 상단의{" "}
                <b
                  onClick={() => {
                    navigate("/request");
                  }}
                >
                  무료 테스트 신청
                </b>{" "}
                버튼을 클릭해주세요!
                <br />
                남겨주신 메일로 소개서를 보내드리겠습니다.
                <br />
                <br />
                <span>(영업일 기준 3일 이내 발송)</span>
              </RatingResultText>
            ) : (
              <RatingResultText>
                변환한 사진은 ‘마이페이지’ 에서도 확인 가능합니다.
                <br />
                <br />
                버추얼 모델에 관심이 있다면
                <br />
                우측 상단의{" "}
                <b
                  onClick={() => {
                    window.open(
                      "https://forms.gle/KFrSUh253bdZAWD88",
                      "_blank"
                    );
                  }}
                >
                  플립션 모델풀 지원
                </b>{" "}
                버튼을 클릭해주세요!
                <br />
                누구나 모델이 될 수 있는 곳,
                <br />
                플립션 모델 에이전시에 오신 걸 환영합니다!
              </RatingResultText>
            )}
            <MiddleBtn
              id="mypage"
              btcolor="orange"
              onClick={() => {
                TagManager.dataLayer(myPageArgs);
                navigate("/mypage");
              }}
            >
              마이페이지 가기
            </MiddleBtn>
          </RatingResultBox>
        )}
        {/* </DialogContent> */}
      </Dialog>
    </>
  );
};

export default ServiceRating;
