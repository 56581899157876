import React, { useEffect, useRef } from "react";
import {
  ChangeStartBox,
  ChangeSubTitle,
  ChangeTitle,
  UploadText,
  UploadText2,
} from "../../styles/ChangeFace";
import { UploadButton } from "../../styles/Button";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useDispatch } from "react-redux";
import { setOpen } from "src/store/slice/pageSlice";
// import { useQueryClient } from "@tanstack/react-query";

interface ChangeStartProps {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChangeStart = (props: ChangeStartProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const needsLogin = () => {
    alert("로그인이 필요합니다");
    dispatch(setOpen());
  };

  // const queryClient = useQueryClient();

  // const updateSignUpCheck = async () => {
  //   await queryClient.refetchQueries(["UserByEmail"]);
  // };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     updateSignUpCheck();
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <Helmet>
        <title>Face Flip | File upload main</title>
      </Helmet>
      <ChangeStartBox>
        <ChangeTitle>사진을 업로드 하세요</ChangeTitle>
        <ChangeSubTitle>
          정면을 바라본 눈, 코, 입이 모두 보이는 사진으로
          <br className="break" /> 업로드해야 변환이 잘 돼요!
        </ChangeSubTitle>
        <UploadButton
          id="error"
          onClick={() => {
            user.authenticated ? handleButtonClick() : needsLogin();
          }}
        >
          사진 업로드
        </UploadButton>
        <UploadText>※ 업로드 가능한 확장자: JPEG, JPG, PNG </UploadText>
        <UploadText2>
          ※ 체험판에서는 ‘이목구비 구별이 어려운 각도, 여러 인물 동시 변환,
          <br className="break" /> FHD을 초과하는 화질에 대한 변환’ 서비스는
          제공하지 않습니다.
        </UploadText2>
      </ChangeStartBox>
      <input
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        id="sideImageUpload"
        onChange={props.handleUpload}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </>
  );
};

export default ChangeStart;
