import styled from "styled-components";
import mainBg from "src/assets/images/main_bg.png";
import { xButton } from "./Login";

// 회사 소개서 신청 페이지
export const RequestContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 935px; */
  min-height: 95vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${mainBg});
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
  }
  ${(props) => props.theme.media.mobile`
  // min-height: 96vh;
  `}
`;
export const RequestFormBox = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 53px;
  width: 100%;
  height: 100%;
  padding: 101px 0;
  ${(props) => props.theme.media.mobile`
  padding: 10% 0;
  gap: 30px;
  `}
`;
export const RequestFormTitle = styled.h3`
  ${(props) => props.theme.text_62px}
  color: #fff;
`;
export const RequestInputInner = styled.div`
  ${(props) => props.theme.optionBox};
  gap: 43px;
  width: 100%;
  max-width: 738px;
  height: 100%;
  min-height: 512px;
  padding: 65px 35px 35px;
  ${(props) => props.theme.media.mobile`
    max-width: 90%;
    gap: 25px;
    min-height: 450px;
    padding: 60px 35px 35px;
`}
  ${(props) => props.theme.media.small`
    padding: 30px 20px;
  `}
`;
export const RequestInputClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  ${xButton}
  z-index: 2;
  &:before,
  &:after {
    background-color: #b9b9b9;
  }
  ${(props) => props.theme.media.mobile`
  right: 17px;
  top: 17px;
`}
`;
export const RequestInputTitle = styled.h3`
  font-weight: 700;
  font-size: 27px;
  line-height: 39px;
  color: #676767;
  ${(props) => props.theme.media.mobile`
    font-size: 20px;
    line-height: 34px;
`}
  @media screen and (max-width: 400px) {
    font-size: 18px;
    line-height: 30px;
  }
`;
export const RequestInputList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24.57px;
  width: 100%;
`;
export const RequestListItem = styled.div`
  position: relative;
  width: 100%;
  > p {
    position: absolute;
    bottom: -25px;
    width: 100%;
    padding: 7px 5px 0 0;
    color: #e94b48;
    font-weight: 600;
    text-align: right;
  }
  ${(props) => props.theme.media.mobile`
  > p {
    font-size: 14px;
  }
`}
`;
export const RequestInputBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 17px;
  width: 100%;
  ${(props) => props.theme.media.tablet`
    gap: 15px;
    `}
  ${(props) => props.theme.media.mobile`
    flex-wrap: wrap;
    gap: 10px;
`}
`;
export const RequestInputLabel = styled.label`
  display: inline-block;
  width: 100%;
  max-width: 122px;
  background: rgba(62, 62, 62, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 800;
  font-size: 26px;
  line-height: 62.43px;
  text-align: center;
  color: #fff;
  ${(props) => props.theme.media.tablet`
    font-size: 25px;
    line-height: 55px;
`}
  ${(props) => props.theme.media.mobile`
    max-width: 100px;
    margin-right: auto;
    font-size: 20px;
    line-height: 40px;
`}
${(props) => props.theme.media.small`
    max-width: 85px;
    font-size: 18px;
`}
`;
export const RequestInput = styled.input`
  width: 100%;
  height: 62.43px;
  background: rgba(202, 202, 202, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  font-size: 26px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  color: #646464;
  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
    font-weight: 500;
  }
  ${(props) => props.theme.media.tablet`
    height: 55px;
    font-size: 25px;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 23px;
`}
${(props) => props.theme.media.small`
    font-size: 18px;
`}
`;

// 회사 소개서 신청 완료 페이지
export const RequestCompleteInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  ${(props) => props.theme.media.tablet`
`}
`;
export const RequestCompleteBox = styled.div`
  ${(props) => props.theme.optionBox}
  gap: 66px;
  width: 100%;
  max-width: 522px;
  height: 407px;
  margin-top: 300px;
  ${(props) => props.theme.media.tablet`
    margin-top: 30%;
  `}
  ${(props) => props.theme.media.mobile`
    margin-top: 45%;
    max-width: 80%;
    height: 100%;
    padding: 60px 10px;
    gap: 30px;
  `};
`;

// 회사 소개서 신청 완료 텍스트
export const RequestCompleteText = styled.h3`
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #676767;
  ${(props) => props.theme.media.mobile`
  font-size: 35px;
  line-height: 45px;
  `}
  ${(props) => props.theme.media.small`
  font-size: 27px;
  line-height: 38px;
  `}
`;

// - 플립션 소개 페이지
export const IntroInner = styled.div`
  ${(props) => props.theme.flexCol}
  justify-content: center;
  padding: 90px 75px;
  gap: 36px;
  ${(props) => props.theme.media.tablet`
  padding: 70px 60px;
  `}
  ${(props) => props.theme.media.mobile`
  padding: 50px 30px;
  gap: 30px;
  `}
`;
export const IntroTextBox = styled.div`
  ${(props) => props.theme.flexCol}
  gap: 19px;
`;
export const IntroTitle = styled.h3`
  font-size: 37px;
  font-weight: 800;
  line-height: 41px;
  text-align: center;
  color: #676767;
  word-break: keep-all;
  ${(props) => props.theme.media.mobile`
  font-size: 34px;
  line-height: 38px;
  `}
  ${(props) => props.theme.media.small`
  font-size: 30px;
  line-height: 34px;
`}
`;
export const IntroText = styled.p`
  font-size: 27px;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  color: #9f9f9f;
  word-break: keep-all;
  ${(props) => props.theme.media.mobile`
  font-size: 22px;
  line-height: 25px;
`}
  ${(props) => props.theme.media.small`
  font-size: 20px;
  line-height: 25px;
`}
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`;
export const IntroLinkBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 470px;
  gap: 40px;
  img {
    width: 100%;
    max-width: 130px;
    height: 100%;
    max-height: 130px;
    /* object-fit: contain; */
    cursor: pointer;
  }
  ${(props) => props.theme.media.tablet`
  gap: 30px;
  img {
    max-width: 85px;
    max-height: 85px;
  }
  `}
  ${(props) => props.theme.media.mobile`
  gap: 20px;
  img {
    max-width: 70px;
    max-height: 70px;
  }
  `}
  @media screen and (max-width: 350px) {
    gap: 12px;
    img {
      max-width: 45px;
      max-height: 45px;
    }
  }
`;
