import React from "react";
import {
  LibraryImg,
  LibraryImgBox,
  LibraryText,
  LibraryBox,
} from "../../styles/User";
import { LibrarySaveBt } from "../../styles/Button";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import TagManager from "react-gtm-module";

interface LibraryProps {
  contents: (
    | {
        src: string;
        blob: Blob;
        name: string;
        selected: boolean;
      }
    | undefined
  )[];
  setContents: React.Dispatch<
    React.SetStateAction<
      (
        | {
            src: string;
            blob: Blob;
            name: string;
            selected: boolean;
          }
        | undefined
      )[]
    >
  >;
}

const Library = (props: LibraryProps) => {
  const handleSelect = (index: number) => {
    const newList = props.contents.map((item, i) => ({
      ...item,
      selected: i === index ? !item!.selected : item!.selected,
    })) as { src: string; blob: Blob; name: string; selected: boolean }[];
    props.setContents(newList);
  };

  const user = useSelector((state: RootState) => state.user);

  const selectedList = props.contents.filter((item) => item!.selected === true);

  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  // 태그매니저 events
  const tagManagerArgs = {
    dataLayer: {
      event: "save_result",
      source: utmSource ? utmSource : "direct",
      page_title: htmlTitle?.innerText,
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  const handleDownload = () => {
    TagManager.dataLayer(tagManagerArgs);
    selectedList.forEach((item) => {
      const url = URL.createObjectURL(item!.blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = item!.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <LibraryBox>
      <Helmet>
        <title>Face Flip | Library</title>
      </Helmet>
      <LibraryImgBox>
        <div>
          {props.contents.map((item, index) => (
            <LibraryImg
              loading="lazy"
              src={item!.src}
              alt={item!.name}
              selected={item!.selected ? "orange" : "grey"}
              key={index}
              onClick={() => handleSelect(index)}
            />
          ))}
        </div>
      </LibraryImgBox>
      {selectedList.length > 0 ? (
        <LibrarySaveBt
          btcolor="orange"
          id="save_result"
          onClick={handleDownload}
        >
          저장하기
        </LibrarySaveBt>
      ) : (
        <LibraryText>
          ※ 저장을 원하는 경우 사진을 클릭해주세요 (복수 선택 가능)
        </LibraryText>
      )}
    </LibraryBox>
  );
};

export default Library;
