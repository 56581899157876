import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { theme as styledTheme } from "./styles/StyledTheme";
import { ThemeProvider } from "styled-components";
import { CssBaseline } from "@mui/material";

export default function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={styledTheme}>
          <CssBaseline />
          <Component {...props} />
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
  return WithRoot;
}
