import React, { useEffect, useState } from "react";
import { WithdrawalContainer } from "../../styles/User";
import WithdrawalReason from "./WithdrawalReason";
import WithdrawalComplete from "./WithdrawalComplete";
import WithdrawalAlert from "./WithdrawalAlert";
import TagManager from "react-gtm-module";

const Withdrawal = () => {
  const [page, setPage] = useState(0);

  // gtm event
  const htmlTitle = document.querySelector("title");
  const utmSource = sessionStorage.getItem("utm_source");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSource ? utmSource : "direct",
      },
    });
  }, []);

  return (
    <WithdrawalContainer>
      {page === 0 && <WithdrawalAlert setPage={setPage} />}
      {page === 1 && <WithdrawalReason setPage={setPage} />}
      {page === 2 && <WithdrawalComplete />}
    </WithdrawalContainer>
  );
};

export default Withdrawal;
