import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import React from "react";
import {
  SignupBox,
  SignupBtBox,
  SignupClose,
  SignupEtcInputBox,
  SignupFormControl,
  SignupInput,
  SignupInputBox,
  SignupInputDiv,
  SignupLabel,
  SignupSelect,
  SignupSelectIcon,
} from "src/styles/Login";
import down from "src/assets/images/scroll_down.png";
import { useNavigate } from "react-router-dom";
import { GenderBt } from "src/styles/Button";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { SignupFormType } from "./SignUp";
import { Helmet } from "react-helmet-async";

export const selectIcon = (props: SelectProps) => {
  return (
    <SignupSelectIcon>
      <img
        src={down}
        alt="down"
        style={{ transform: props.open ? "rotate(90deg)" : "rotate(0deg)" }}
      />
    </SignupSelectIcon>
  );
};

export const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 190,
    },
  },
  // disableScrollLock: true,
};

interface PersonalSignupProps {
  register: UseFormRegister<SignupFormType>;
  errors: FieldErrors<SignupFormType>;
  needs: string[];
  gender: number;
  setGender: (value: number) => void;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
  signUpCancel: () => void;
}

const PersonalSignup = (props: PersonalSignupProps) => {
  const navigate = useNavigate();

  const needsList = [
    "초상권이 걱정됨(전업)",
    "초상권이 걱정됨(부업)",
    "브랜드 니즈와 안 맞음(얼굴)",
    "브랜드 니즈와 안 맞음(바디)",
    "에이전시에 소속되기 어려움",
    "모델 구인 정보를 찾기 어려움",
    "어떤 브랜드인지 신뢰하기 어려움",
    "브랜드와 일정 조율이 어려움",
    "모델 활동비가 너무 적음",
    "기타",
  ];

  const isEtcIncluded = props.needs.includes("기타");

  return (
    <SignupBox>
      <Helmet>
        <title>Face Flip | Sign up_model</title>
      </Helmet>
      <SignupClose
        onClick={() => {
          if (window.confirm("회원가입을 취소하시겠습니까?")) {
            props.signUpCancel();
          }
        }}
      />
      <SignupInputBox>
        <SignupLabel>1. 성명</SignupLabel>
        <SignupInputDiv>
          <SignupInput
            type="text"
            placeholder=" ( 본인 이름을 적어주세요 ) "
            {...props.register("name", {
              required: "이름을 입력해주세요",
              minLength: {
                value: 2,
                message: "2글자 이상 입력해주세요",
              },
              pattern: {
                value: /^[A-za-z0-9가-힣]{2,10}$/,
                message:
                  "2 ~ 10글자 사이의 한글, 영문 대소문자, 숫자를 입력해주세요",
              },
            })}
          />
          {props.errors.name && <p>{props.errors.name.message}</p>}
        </SignupInputDiv>
      </SignupInputBox>
      <SignupInputBox>
        <SignupLabel>2. 나이</SignupLabel>
        <SignupInputDiv>
          <SignupInput
            type="text"
            placeholder=" ( 만 나이로 적어주세요 ) "
            {...props.register("age", {
              required: "나이를 입력해주세요",
              pattern: {
                value: /^(0|[1-9][0-9]?|1[0-4][0-9]|200)$/,
                message: "나이를 입력해주세요",
              },
            })}
          />
          {props.errors.age && <p>{props.errors.age.message}</p>}
        </SignupInputDiv>
      </SignupInputBox>
      <SignupInputBox>
        <SignupLabel>3. 성별</SignupLabel>
        <SignupBtBox>
          <GenderBt
            selected={props.gender === 2 ? "orange" : "grey"}
            onClick={() => {
              props.setGender(2);
            }}
          >
            여성
          </GenderBt>
          <GenderBt
            selected={props.gender === 1 ? "orange" : "grey"}
            onClick={() => {
              props.setGender(1);
            }}
          >
            남성
          </GenderBt>
        </SignupBtBox>
      </SignupInputBox>
      <SignupInputBox>
        <SignupLabel>4. 니즈</SignupLabel>
        <SignupFormControl>
          <InputLabel
            id="select-helper-label"
            sx={{ fontFamily: "'NanumSquareAcr', sans-serif" }}
          >
            모델 활동을 하시는데 불편했던 점을 알려주세요
          </InputLabel>
          <SignupSelect
            multiple
            id="select-helper-label"
            value={props.needs}
            onChange={props.handleChange}
            IconComponent={selectIcon}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected: any) => selected.join(", ")}
            MenuProps={SelectMenuProps}
          >
            <MenuItem value={[]} disabled>
              모델 활동을 하시는데 불편했던 점을 알려주세요 (복수 선택 가능)
            </MenuItem>
            {needsList.map((need) => (
              <MenuItem key={need} value={need}>
                <Checkbox checked={props.needs.indexOf(need) > -1} />
                <ListItemText primary={need} />
              </MenuItem>
            ))}
          </SignupSelect>
        </SignupFormControl>
      </SignupInputBox>
      <SignupEtcInputBox isEtcIncluded={isEtcIncluded}>
        <SignupLabel>4.1 기타 사유</SignupLabel>
        <SignupInputDiv>
          <SignupInput
            type="text"
            placeholder=" ( 기타 사유를 입력해주세요 ) "
            {...props.register("etc", { required: isEtcIncluded })}
          />
          {props.errors.etc && <p>사유를 입력해주세요</p>}
        </SignupInputDiv>
      </SignupEtcInputBox>
    </SignupBox>
  );
};

export default PersonalSignup;
