import React, { useEffect } from "react";
import { NoImageBox, NoImageImg, NoImageText } from "../../styles/User";
import { MiddleBtn } from "../../styles/Button";
import noImg from "src/assets/images/noImg.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const NoImage = () => {
  const navigate = useNavigate();

  return (
    <NoImageBox>
      <Helmet>
        <title>Face Flip | Library fail</title>
      </Helmet>
      <NoImageImg src={noImg} alt="noImg" />
      <NoImageText>
        아직 변환한 사진이 없네요~!
        <br className="break-m" /> 사진 변환을 시작해 보세요!
      </NoImageText>
      <MiddleBtn
        btcolor="orange"
        onClick={() => {
          navigate("/change");
        }}
      >
        변환 시작하기
      </MiddleBtn>
    </NoImageBox>
  );
};

export default NoImage;
