import React from "react";
import {
  RequestCompleteBox,
  RequestCompleteInner,
  RequestCompleteText,
} from "../../styles/Request";
import { useNavigate } from "react-router-dom";
import { MiddleBtn } from "../../styles/Button";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";

const RequestDone = () => {
  const navigate = useNavigate();

  return (
    <Fade>
      <Helmet>
        <title>Face Flip | IR done</title>
      </Helmet>
      <RequestCompleteInner>
        <RequestCompleteBox>
          <RequestCompleteText>
            무료 테스트 신청이
            <br />
            완료되었습니다!
          </RequestCompleteText>
          <MiddleBtn
            btcolor="orange"
            onClick={() => {
              navigate("/");
            }}
          >
            메인 페이지로
          </MiddleBtn>
        </RequestCompleteBox>
      </RequestCompleteInner>
    </Fade>
  );
};

export default RequestDone;
