import { CircularProgress } from "@mui/material";
import React from "react";
import { LoginLoadingText } from "src/styles/Login";
import { LibraryBox } from "src/styles/User";

const LibraryLoading = () => {
  return (
    <LibraryBox style={{ justifyContent: "center", gap: 25 }}>
      <CircularProgress color="primary" />
      <LoginLoadingText>로딩중 입니다</LoginLoadingText>
    </LibraryBox>
  );
};

export default LibraryLoading;
