import styled, { css } from "styled-components";
import up from "src/assets/images/scroll_up.png";
import down from "src/assets/images/scroll_down.png";
import check from "src/assets/images/Check.png";
import { FormControl, FormGroup, Select } from "@mui/material";

// X 버튼 레이아웃
export const xButton = css`
  width: 37.55px;
  height: 37.55px;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  &:before {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  ${(props) => props.theme.media.mobile`
  width: 27px;
  height: 27px;
`}
`;

// - 로그인창
export const LoginContainer = styled.div`
  ${(props) => props.theme.flexCol}
  justify-content: center;
  padding: 63px 75px;
  gap: 79px;
  ${(props) => props.theme.media.tablet`
  padding: 63px 60px;
  gap: 50px;
  `}
  ${(props) => props.theme.media.mobile`
  padding: 40px 30px;
  gap: 25px;
  `}
`;
export const LoginClose = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  ${xButton}
  &:before,
  &:after {
    background-color: #b9b9b9;
  }
  ${(props) => props.theme.media.mobile`
    right: 18px;
    top: 18px;
  `}
`;
export const LoginTitle = styled.p`
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 37px;
  line-height: 41px;
  text-align: center;
  color: #676767;
  ${(props) => props.theme.media.mobile`
  font-size: 34px;
  line-height: 38px;
`}
  ${(props) => props.theme.media.small`
  font-size: 30px;
  line-height: 34px;
`}
`;
export const LoginText = styled.span`
  font-size: 27px;
  font-weight: 700;
  line-height: 30px;
  color: #9f9f9f;
  text-align: center;
  ${(props) => props.theme.media.small`
  font-size: 22px;
  line-height: 25px;
`}
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`;
export const LoginSNSBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 490px;
  gap: 40px;
  img {
    max-width: 130px;
    max-height: 130px;
    cursor: pointer;
  }
  ${(props) => props.theme.media.tablet`
  gap: 30px;
  img {
    max-width: 90px;
    max-height: 90px;
  }
  `}
  ${(props) => props.theme.media.mobile`
  gap: 20px;
  img {
    max-width: 75px;
    max-height: 75px;
  }
`}
  @media screen and (max-width: 350px) {
    gap: 12px;
    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
`;

// 로그인 로딩 페이지
export const LoginLoadingContainer = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: 25px;
  min-height: 95vh;
  background: #12100f;
  ${(props) => props.theme.media.mobile`
  // min-height: 96vh;
`}
`;
export const LoginLoadingText = styled.span`
  font-size: 25px;
  font-weight: 800;
  text-align: center;
  color: #fff;
`;

// 회원가입 페이지
export const SignupInner = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  gap: 33px;
  width: 100%;
  height: 100%;
  padding: 57px 5%;
  ${(props) => props.theme.media.mobile`
  padding: 7% 5%;
`}
`;
export const SignupClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  ${xButton}
  z-index: 2;
  &:before,
  &:after {
    background-color: #e1dede;
  }
  ${(props) => props.theme.media.mobile`
  right: 15px;
  top: 15px;
`}
`;
export const SignupTitle = styled.h2`
  font-size: 54px;
  font-weight: 800;
  line-height: 70px;
  text-align: center;
  color: #fff;
  ${(props) => props.theme.media.tablet`
    font-size: 48px;
    line-height: 54px;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 40px;
    line-height: 54px;
`}
  ${(props) => props.theme.media.small`
    font-size: 35px;
    line-height: 50px;
`}
`;
export const SignupForm = styled.form`
  ${({ theme }) => theme.flexCol}
  gap: 33px;
  width: 100%;
  max-width: 780px;
  ${(props) => props.theme.media.mobile`
  gap: 20px;
`}
`;
export const SignupBox = styled.div`
  ${({ theme }) => theme.signUpBox}
  gap: 24px;
  width: 100%;
  min-height: 621px;
  padding: 35px 32px;
  ${(props) => props.theme.media.mobile`
  gap: 18px;
  height: 100%;
  padding: 25px;
`}
`;
export const SignupInputBox = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  width: 100%;
  font-weight: 500;
`;
export const SignupEtcInputBox = styled.div<{ isEtcIncluded: boolean }>`
  ${({ theme }) => theme.flexCol}
  position: relative;
  width: 100%;
  height: ${(props) => (props.isEtcIncluded ? "145px" : "0")};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  font-weight: 500;
`;
export const SignupLabel = styled.span`
  width: 100%;
  margin-bottom: 18px;
  font-size: 22px;
  font-weight: 800;
  line-height: 29px;
  color: #fff;
  text-align: left;
  ${(props) => props.theme.media.small`
  font-size: 20px;
`}
`;
export const SignupInputDiv = styled.div`
  position: relative;
  width: 100%;
  max-width: 655px;
  font-weight: 500;
  > p {
    position: absolute;
    width: 100%;
    right: 25px;
    padding: 5px;
    color: #ff0000;
    text-shadow: 0px 0 #616161, 0 0.5px #616161, -0.5px 0 #616161,
      0 -0.5px #616161;
    font-weight: 500;
    text-align: right;
  }
  ${(props) => props.theme.media.small`
  > p {
    right: 15px;
    font-size: 13px;
  }
`}
`;
export const SignupInput = styled.input`
  width: 100%;
  height: 72px;
  border-radius: 16px;
  border: 4px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border: 4px solid #9f9f9f;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 25px;
  font-weight: 500;
  line-height: 32.63px;
  text-align: center;
  color: #fff;
  &::placeholder {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  ${(props) => props.theme.media.mobile`
    font-size: 22px;
    height: 64px;
    &::placeholder {
      font-size: 22px;
    }
`}
  ${(props) => props.theme.media.small`
    font-size: 18px;
    height: 56px;
    &::placeholder {
      font-size: 18px;
    }
`}
`;
export const SignupFormControl = styled(FormControl)`
  width: 100%;
  max-width: 655px;
  font-weight: 500;
  .MuiFormLabel-root {
    width: 100%;
    font-size: 25px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #fff;
  }
  .MuiFormLabel-root.MuiInputLabel-shrink {
    display: none !important;
  }
  ${(props) => props.theme.media.mobile`
  .MuiFormLabel-root {
    font-size: 22px !important;
  }
`}
  ${(props) => props.theme.media.small`
  .MuiFormLabel-root {
    padding-left: 5px;
    padding-right: 35px;
    text-align: left;
    font-size: 18px !important;
  }
`}
`;
export const SignupSelect = styled(Select)`
  width: 100%;
  max-width: 655px;
  height: 72px;
  border-radius: 16px !important;
  border: 4px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border: 4px solid #9f9f9f;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: #fff;
  font-size: 25px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #fff !important;
  .MuiSelect-select {
    font-family: "NanumSquareAcr", sans-serif !important;
    font-size: 25px !important;
    padding-right: 60px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .Mui-selected {
    background: #fff !important;
  }
  ${(props) => props.theme.media.mobile`
    height: 64px;
    font-size: 22px !important;
    .MuiSelect-select {
      font-size: 22px !important;
    }
`}
  ${(props) => props.theme.media.small`
    height: 56px;
    font-size: 18px !important;
    .MuiSelect-select {
      font-size: 18px !important;
    }
`}
`;
export const SignupSelectIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 54px;
  height: 54px;
  background: #81818180;
  border-radius: 10px;
  pointer-events: none;
  img {
    position: absolute;
    right: 10px;
    top: 17px;
    width: 32px;
  }
  ${(props) => props.theme.media.mobile`
    width: 46px;
    height: 46px;
    img {
      right: 8px;
      top: 15px;
      width: 28px;
    }
`}
  ${(props) => props.theme.media.small`
    width: 38px;
    height: 38px;
    img {
      right: 6.5px;
      top: 12px;
      width: 24px;
    }
`}
`;
export const SignupBtBox = styled.div`
  display: flex;
  gap: 17px;
  justify-content: center;
  width: 100%;
`;
// 약관동의 페이지
export const AgreemenInner = styled.div`
  ${({ theme }) => theme.flexCol}
  gap: 33px;
  padding: 57px 40px;
  ${(props) => props.theme.media.mobile`
  padding: 7% 5%;
`}
`;
export const AgreementTitle = styled.h2`
  font-weight: 800;
  font-size: 54px;
  line-height: 70px;
  color: #fff;
  z-index: 1;
  ${(props) => props.theme.media.tablet`
    font-size: 48px;
    line-height: 54px;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 40px;
    line-height: 54px;
`}
  ${(props) => props.theme.media.small`
    font-size: 35px;
    line-height: 50px;
`}
`;
export const AgreementFormBox = styled(FormGroup)`
  ${({ theme }) => theme.signUpBox}
  justify-content: flex-start;
  gap: 49px;
  width: 100%;
  /* height: 100%; */
  max-width: 780px;
  height: 621px;
  padding: 15px;
  padding-top: 45px;
  ${(props) => props.theme.media.mobile`
  gap: 65px;
`}
  @media screen and (max-width: 400px) {
    padding-top: 30px;
  }
`;
export const AgreementClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  ${xButton}
  &:before,
  &:after {
    background-color: #e1dede;
  }
  ${(props) => props.theme.media.mobile`
  right: 15px;
  top: 15px;
`}
`;
export const AgreementFormlist = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
export const AgreementFormTitle = styled.p`
  font-weight: 800;
  font-size: 22px;
  color: #fff;
  ${(props) => props.theme.media.mobile`
  font-size: 20px;
`}
`;
export const AgreementContent = styled.div`
  width: 100%;
  max-width: 726px;
  height: 180px;
  background: #373737;
  border-radius: 16px;
  p {
    height: 156px;
    overflow-y: scroll;
    margin: 12px 13px;
    padding-left: 6px;
    padding-right: 23px;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    white-space: pre-line;
    word-wrap: break-word;
    &::-webkit-scrollbar {
      width: 40px;
    }
    &::-webkit-scrollbar-track {
      background: #adadad;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ff6510;
      box-shadow: 3px 4px 4px 0px #00000040;
    }
    &::-webkit-scrollbar-button:start:decrement {
      display: block;
      width: 40px;
      height: 40px;
      background: #777 url(${up}) no-repeat;
      background-size: 32px;
      background-position: center 2px;
      border-radius: 10px 10px 0 0;
    }
    &::-webkit-scrollbar-button:end:increment {
      display: block;
      width: 40px;
      height: 40px;
      background: #777 url(${down}) no-repeat;
      background-position: center 10px;
      background-size: 32px;
      border-radius: 0 0 10px 10px;
    }
  }
  ${(props) => props.theme.media.mobile`
p{
  font-size: 16px;
  &::-webkit-scrollbar {
    width: 25px;
  }
  &::-webkit-scrollbar-button:start:decrement {
    display: block;
    width: 25px;
    height: 25px;
    background-size: 90%;
    background-position: center 1px;
  }
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    width: 25px;
    height: 25px;
    background-size: 90%;
    background-position: center 6px;
  }
}
`}
`;
export const AgreementButton = styled.div`
  position: absolute;
  width: 180px;
  height: 50px;
  right: 0;
  bottom: -55.5px;
  span {
    position: absolute;
    top: 14px;
    font-size: 23px;
    font-weight: 800;
    color: #fff;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 16px;
    width: 38px;
    height: 38px;
    border: 3px solid #d5d5d5;
    background: #000000a6;
    border-radius: 6px;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  ${(props) => props.theme.media.mobile`
  height: 40px;
  right: -40px;
  bottom: -42.5px;
  span {
    font-size: 20px;
    top: 9px;
  }
  &:after {
      width: 30px;
      height: 30px;
      bottom: -12px;
      left: 11px;
    }
`}
`;
export const AgreemenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: relative;
  display: inline-block;
  width: 54px;
  height: 45px;
  appearance: none;
  z-index: 2;
  &:checked {
    background-image: url(${check});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  /* &::before {
    content: "";
    position: absolute;
    bottom: -23px;
    right: 0;
    width: 38px;
    height: 38px;
    border: 3px solid #d5d5d5;
    background: #000;
    border-radius: 6px;
    transform: translate(-50%, -50%);
  } */
  ${(props) => props.theme.media.mobile`
    width: 35px;
    height: 35px;
`}
`;
