import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ResultState {
  image: string | null;
  // blob: any;
  name: string | null;
  original: string | null;
  id: string | null;
}

const initialState: ResultState = {
  image: null,
  // blob: null,
  name: null,
  original: null,
  id: null,
};

const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    setSrc: (
      state,
      action: PayloadAction<{
        image: string | null;
        original: string | null;
      }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setId: (
      state,
      action: PayloadAction<{
        id: string | null;
        name: string | null;
      }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    clearResult(state) {
      return initialState;
    },
  },
});

export const { setSrc, setId, clearResult } = resultSlice.actions;

export default resultSlice.reducer;
