import { Button } from "@mui/material";
import styled from "styled-components";

// 중간 크기 버튼
export const MiddleBtn = styled(Button)<{ btcolor: string }>`
  ${({ theme, btcolor }) =>
    btcolor === "orange" ? theme.orangeBt : theme.greyBt}
  width: 100%;
  max-width: 210px;
  height: 62px;
  border-radius: 14px !important;
  font-size: 27px !important;
  padding: 5px 17px !important;
  ${(props) => props.theme.media.tablet`
    max-width: 180px;
    font-size: 24px !important;
    height: 58px;
`}
  ${(props) => props.theme.media.mobile`
    max-width: 165px;
    padding: 7px 15px !important;
    font-size: 22px !important;
    padding: 7px !important;
    height: 55px;
`}
  ${(props) => props.theme.media.small`
    padding: 7px 10px !important;
    max-width: 140px;
    font-size: 18px !important;
    height: 50px;
    `}
  @media screen and (max-width: 400px) {
    font-size: 16px !important;
  }
`;
// 메인페이지 선택 버튼
export const MainSelectBt = styled(Button)<{ btcolor: string }>`
  ${({ theme }) => theme.greyBt}
  width: 100%;
  max-width: 605px;
  height: 110px;
  border-radius: 32px !important;
  font-size: 37px !important;
  font-weight: 800 !important;
  line-height: 41px;
  text-align: center;
  &:hover {
    background: #ff6410 !important;
  }
  ${(props) => props.theme.media.tablet`
  height: 100px;
  font-size: 32px !important;
`}
  ${(props) => props.theme.media.mobile`
  height: 75px;
  font-size: 22px !important;
`}
  @media screen and (max-width: 400px) {
    height: 65px;
    font-size: 20px !important;
  }
`;

// 로그인 버튼
export const LoginButton = styled.span<{ selected: string }>`
  color: #b3b3b3 !important;
  font-weight: 700 !important;
  font-size: 27px !important;
  line-height: 30px !important;
  color: ${(props) =>
    props.selected === "orange" ? "#E6590E !important" : "#9f9f9f"};
  transition: color 0.25s ease-in-out;
  &:hover {
    color: ${(props) =>
      props.selected === "orange"
        ? "#ff6410 !important"
        : "#f5f5f5 !important"};
  }
  cursor: pointer;
  ${(props) => props.theme.media.tablet`
    font-size: 20px !important;
    line-height: 24px !important;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 16px !important;
    line-height: 20px !important;
`}
`;
// 회원가입 성별 체크 버튼
export const GenderBt = styled(Button)<{ selected: string }>`
  width: 319px;
  height: 72px;
  border-radius: 16px !important;
  border: 4px solid !important;
  border-color: ${(props) =>
    props.selected === "orange" ? "#d6d6d6 !important" : "#9f9f9f !important"};
  background: ${(props) =>
    props.selected === "orange"
      ? "#ff6410 !important"
      : "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) !important"};
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 25px !important;
  font-weight: ${(props) =>
    props.selected === "orange" ? "800 !important" : "500 !important"};
  line-height: 33px !important;
  color: #fff !important;
  text-align: center;
  ${(props) => props.theme.media.mobile`
    font-size: 22px !important;
    height: 64px;
`}
  ${(props) => props.theme.media.small`
    font-size: 18px !important;
    height: 56px;
`}
`;
// 회사 소개서 요청 버튼
export const RequestButton = styled(Button)`
  ${({ theme }) => theme.orangeBt}
  width: 231px;
  height: 67px;
  font-size: 23px !important;
  ${(props) => props.theme.media.tablet`
    width: 170px;
    height: 50px;
    font-size: 18px !important;
`}
`;
// 얼굴 변환 버튼
export const ChangeButton = styled(Button)`
  ${({ theme }) => theme.orangeBt}
  width: 516px;
  height: 95px;
  font-size: 37px !important;
  ${(props) => props.theme.media.tablet`
    width: 330px;
    height: 70px;
    font-size: 25px !important;
`}
  ${(props) => props.theme.media.mobile`
    width: 245px;
    height: 50px;
    font-size: 20px !important;
`}
`;
export const FaceCategoryBt = styled(Button)<{ selected: string }>`
  ${({ theme }) => theme.greyBt}
  width: 100%;
  max-width: 287px;
  height: 80px;
  border-radius: 14px !important;
  font-size: 28px !important;
  font-weight: 800 !important;
  text-align: center;
  word-break: keep-all;
  background: ${(props) => props.selected === "orange" && "#ff6410 !important"};
  &:hover {
    background: #ff6410 !important;
  }
  @media screen and (max-width: 1400px) {
    font-size: 25px !important;
  }
  ${(props) => props.theme.media.tablet`
  font-size: 20px !important;
  height: 70px;
`}
  ${(props) => props.theme.media.small`
  font-size: 14px !important;
  `}
  @media screen and (max-width: 400px) {
    font-size: 12px !important;
  }
`;

// 얼굴 셀렉트 버튼
export const SelectButton = styled(MiddleBtn)`
  position: absolute !important;
  right: 0;
  bottom: -105.43px;
  ${(props) => props.theme.media.tablet`
  bottom: -95px;
`}
  ${(props) => props.theme.media.mobile`
  bottom: -85px;
`}
`;

// 사진 업로드 버튼
export const UploadButton = styled(Button)`
  ${({ theme }) => theme.orangeBt}
  width: 516px;
  height: 95px;
  font-size: 39px !important;
  ${(props) => props.theme.media.tablet`
    width: 300px;
    height: 70px;
    font-size: 25px !important;
`}
  ${(props) => props.theme.media.mobile`
    width: 180px;
    height: 50px;
    font-size: 20px !important;
`}
`;

// 사진 저장 버튼
export const SaveButton = styled(Button)<{ btcolor: string }>`
  ${({ theme, btcolor }) =>
    btcolor === "orange" ? theme.orangeBt : theme.greyBt}
  width: 100%;
  max-width: 176px;
  height: 100%;
  max-height: 70px;
  padding: 15px !important;
  border-radius: 14px !important;
  font-weight: 800 !important;
  font-size: 26px !important;
  line-height: 35px !important;
  word-break: keep-all;
  ${(props) => props.theme.media.tablet`
  max-width: 150px;
  font-size: 22px !important;
  `}
  ${(props) => props.theme.media.mobile`
  height: 56px;
  font-size: 20px !important;
  line-height: 24px !important;
  `}
  @media screen and (max-width: 400px) {
    font-size: 16px !important;
  }
`;

// 평가 제출 버튼
export const RatingButton = styled(MiddleBtn)`
  margin-top: 43px !important;
  ${(props) => props.theme.media.tablet`
    margin-top: 30px !important;
`}
  ${(props) => props.theme.media.mobile`
    margin-top: 20px !important;
`}
`;

// 라이브러리 저장 버튼
export const LibrarySaveBt = styled(MiddleBtn)`
  position: absolute;
  bottom: 116px;
  ${(props) => props.theme.media.mobile`
  bottom: 95px;
`}
  ${(props) => props.theme.media.small`
  bottom: 90px;
`}
`;

// 설문1 버튼
export const Survey1Bt = styled(MiddleBtn)`
  margin-top: 88px !important;
  ${(props) => props.theme.media.tablet`
    margin-top: 50px !important;
`}
  ${(props) => props.theme.media.mobile`
    margin-top: 40px !important;
`}
`;
// 설문2 버튼
export const Survey2Bt = styled(MiddleBtn)`
  margin-top: 42px !important;
  ${(props) => props.theme.media.tablet`
    margin-top: 30px !important;
`}
`;

// 이유 버튼
export const WithdrawalBtn = styled(Button)<{ btcolor: string }>`
  ${({ theme, btcolor }) =>
    btcolor === "orange" ? theme.orangeBt : theme.greyBt}
  width: 100%;
  max-width: 460px;
  height: 72px;
  border-radius: 16px !important;
  font-size: 28px !important;
  padding: 5px !important;
  ${(props) => props.theme.media.tablet`
  height: 64px;
  font-size: 24px !important;
  `}
  ${(props) => props.theme.media.mobile`
  height: 56px;
  font-size: 20px !important;
  `}
  ${(props) => props.theme.media.small`
  height: 48px;
  font-size: 18px !important;
  `}
  @media screen and (max-width: 400px) {
    font-size: 15px !important;
  }
`;
