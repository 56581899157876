import { CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";
import { LoginLoadingContainer, LoginLoadingText } from "src/styles/Login";

interface LoadingProps {
  children: ReactNode;
}

const Loading: React.FC<LoadingProps> = ({ children }) => {
  return (
    <LoginLoadingContainer>
      <CircularProgress color="primary" />
      <LoginLoadingText>{children}</LoginLoadingText>
    </LoginLoadingContainer>
  );
};

export default Loading;
