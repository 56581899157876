import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAuth, setAuth, setId } from "src/store/slice/userSlice";
import Loading from "../../components/Loading";
import { Auth } from "aws-amplify";

const SNSLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const code = new URL(window.location.href).searchParams.get("code");

  const state = new URL(window.location.href).searchParams.get("state");

  // const naverClient = process.env.REACT_APP_NAVER_CLIENT_ID;
  // const naverSecret = process.env.REACT_APP_NAVER_CLIENT_SECRET;

  const naverLogin = () => {
    sessionStorage.setItem("code", code!);
    const params = {
      sns_type: "naver",
      code,
    };
    axios
      .post(
        "https://menjm21qci.execute-api.ap-northeast-2.amazonaws.com/prod",
        params
      )
      .then(async (res) => {
        // console.log(res);
        if (res.data.body.message === "kakao") {
          alert(
            "카카오 연동으로 가입된 동일한 이메일 계정이 있습니다. 다른 계정으로 로그인 해주세요."
          );
          sessionStorage.removeItem("code");
          dispatch(clearAuth());
          navigate("/");
          return;
        } else if (res.data.body.message === "google") {
          alert(
            "구글 연동으로 가입된 동일한 이메일 계정이 있습니다. 다른 계정으로 로그인 해주세요."
          );
          sessionStorage.removeItem("code");
          dispatch(clearAuth());
          navigate("/");
          return;
        }
        // const result = {
        //   authenticated: true,
        //   sns: "naver",
        //   id: "naver",
        // };
        // dispatch(setAuth(result));
        // const user = res.data.body;
        // await Auth.signIn(user.username, user.password);
        // // setUsername(user.username);
        // dispatch(setId({ id: user.username }));
        // navigate("/");
        try {
          const user = res.data.body;
          await Auth.signIn(user.username, user.password);
          dispatch(setId({ id: user.username }));
          navigate("/");
        } catch (error) {
          console.log(error);
          alert("로그인에 실패했습니다. 다시 시도해주세요.");
          sessionStorage.removeItem("access_token");
          dispatch(clearAuth());
        }
      })
      .catch((err) => {
        console.log(err);
        alert("로그인에 실패했습니다. 다시 시도해주세요.");
        sessionStorage.removeItem("code");
        dispatch(clearAuth());
        navigate("/");
      });
  };

  useEffect(() => {
    naverLogin();
  }, [code]);

  return (
    <>
      <Loading>
        로그인 중입니다
        <br />
        잠시만 기다려주세요
      </Loading>
    </>
  );
};

export default SNSLogin;
