import React, { useEffect, useState } from "react";
import { ChangeContainer } from "../../styles/ChangeFace";
import ChangeCaution from "./ChangeCaution";
import ChangeUpload from "./ChangeUpload";
import SelectFace from "./SelectFace";
import ChangeStart from "./ChangeStart";
import UploadFail from "./UploadFail";
import ChangeFail from "./ChangeFail";
import TagManager from "react-gtm-module";

const ChangeFace = () => {
  const [page, setPage] = useState(0);

  const [error, setError] = useState("");

  // 파일
  const [file, setFile] = useState<{
    name: string;
    dataUrl: string;
    file?: File;
  } | null>(null);

  // gtm event
  const htmlTitle = document.querySelector("title");
  const utmSource = sessionStorage.getItem("utm_source");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSource ? utmSource : "direct",
      },
    });
  }, []);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = (event.target.files as FileList)[0];
    if (!uploadedFile) {
      alert("업로드에 실패하였습니다.");
      return;
    }
    // if (uploadedFile.size > 4 * 1024 * 1024) {
    //   // alert("4MB 이하의 파일만 업로드 가능합니다.");
    //   setPage(3);
    //   return;
    // }
    const image = new Image();
    image.src = URL.createObjectURL(uploadedFile);
    image.onload = () => {
      if (image.width > 3024 || image.height > 4032) {
        const errorArgs = {
          dataLayer: {
            event: "error",
            source: utmSource ? utmSource : "direct",
            error_etc: "fail upload",
            error_file_size: uploadedFile.size,
          },
        };
        TagManager.dataLayer(errorArgs);
        setPage(3);
        return;
      }
      setFile({
        name: uploadedFile.name,
        dataUrl: URL.createObjectURL(uploadedFile),
        file: uploadedFile,
      });
      setPage(2);
    };
  };
  return (
    <ChangeContainer>
      {page === 0 && <ChangeStart handleUpload={handleUpload} />}
      {/* {page === 1 && <ChangeCaution handleUpload={handleUpload} />} */}
      {page === 2 && (
        <ChangeUpload
          file={file}
          handleUpload={handleUpload}
          setPage={setPage}
        />
      )}
      {page === 3 && <UploadFail handleUpload={handleUpload} />}
      {page === 4 && (
        <SelectFace
          file={file}
          setPage={setPage}
          error={error}
          setError={setError}
        />
      )}
      {page === 5 && <ChangeFail handleUpload={handleUpload} error={error} />}
    </ChangeContainer>
  );
};

export default ChangeFace;
