import React, { useEffect, useState } from "react";
import {
  WithdrawalBtBox,
  WithdrawalInput,
  WithdrawalInputBox,
  WithdrawalReasonBox,
  WithdrawalReasonInner,
  WithdrawalReasonSubTitle,
  WithdrawalReasonTitle,
} from "../../styles/User";
import { WithdrawalBtn, MiddleBtn } from "../../styles/Button";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useDispatch } from "react-redux";
import { clearAuth } from "src/store/slice/userSlice";
import { Auth } from "aws-amplify";
import TagManager from "react-gtm-module";
import { RequestInputClose } from "src/styles/Request";
import { useNavigate } from "react-router";
import { kakaoWithdrawalApi } from "src/lib/refresh";

interface WithdrawalReasonProps {
  setPage: (page: number) => void;
}

interface FormValuesType {
  content: string;
}

const WithdrawalReason = (props: WithdrawalReasonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValuesType>();

  const user = useSelector((state: RootState) => state.user);

  // 탈퇴
  // const { mutate: reasonMutate } = useCreateWithdrawalMutation();

  const [reason, setReason] = useState(0);

  const kakaoWithdrawal = () => {
    kakaoWithdrawalApi
      .post("")
      .then((res) => {
        // console.log("카카오 탈퇴 성공", res);
        sessionStorage.removeItem("access_token");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const googleWithdrawal = () => {
    const accessToken = window.sessionStorage.getItem("access_token");
    axios
      .post(
        `https://oauth2.googleapis.com/revoke?token=${accessToken}`,
        {},
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((res) => {
        // console.log("구글 탈퇴 성공", res);
        sessionStorage.removeItem("access_token");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 태그매니저 events
  const utmSource = sessionStorage.getItem("utm_source");

  const tagManagerArgs = {
    dataLayer: {
      event: "delete_account",
      source: utmSource ? utmSource : "direct",
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  const onSubmit: SubmitHandler<FormValuesType> = async (submit) => {
    if (reason === 0) {
      alert("탈퇴 이유를 선택해주세요");
    } else {
      TagManager.dataLayer(tagManagerArgs);
      const reasonText =
        reason === 1
          ? "개인 정보 삭제를 위해"
          : reason === 2
          ? "더 이상 관심/재미가 없어서"
          : reason === 3
          ? "변환 결과물이 만족스럽지 않아서"
          : submit.content;
      if (user.sns === "naver") {
        const code = window.sessionStorage.getItem("code");
        const params = { id: user.id!, reason: reasonText, code };
        axios
          .post(
            "https://tz0iurhyzi.execute-api.ap-northeast-2.amazonaws.com/dev/user/withdrawal",
            params
          )
          .then(async (res) => {
            // console.log(res);
            sessionStorage.removeItem("code");
            dispatch(clearAuth());
            props.setPage(2);
            try {
              await Auth.signOut();
            } catch (err) {
              console.log(err);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const params = { id: user.id!, reason: reasonText };
        axios
          .post(
            "https://tz0iurhyzi.execute-api.ap-northeast-2.amazonaws.com/dev/user/withdrawal",
            params
          )
          .then(async (res) => {
            // console.log(res);
            if (user.sns === "kakao") {
              await kakaoWithdrawal();
            } else {
              await googleWithdrawal();
            }
            sessionStorage.removeItem("access_token");
            dispatch(clearAuth());
            props.setPage(2);
            try {
              await Auth.signOut();
            } catch (err) {
              console.log(err);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <WithdrawalReasonInner onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Face Flip | Withdrawal survey</title>
      </Helmet>
      <WithdrawalReasonBox>
        <RequestInputClose
          onClick={() => {
            if (window.confirm("탈퇴 신청을 취소하시겠습니까?")) {
              navigate(-1);
            }
          }}
        />
        <WithdrawalReasonTitle>탈퇴하기</WithdrawalReasonTitle>
        <WithdrawalReasonSubTitle>
          본 사이트를 탈퇴하시려는
          <br className="break-m" /> 이유가 무엇입니까?
        </WithdrawalReasonSubTitle>
        <WithdrawalBtBox>
          <WithdrawalBtn
            btcolor={reason === 1 ? "orange" : "grey"}
            onClick={() => {
              setReason(1);
            }}
          >
            개인 정보 삭제를 위해
          </WithdrawalBtn>
          <WithdrawalBtn
            btcolor={reason === 2 ? "orange" : "grey"}
            onClick={() => {
              setReason(2);
            }}
          >
            더 이상 관심/재미가 없어서
          </WithdrawalBtn>
          <WithdrawalBtn
            btcolor={reason === 3 ? "orange" : "grey"}
            onClick={() => {
              setReason(3);
            }}
          >
            변환 결과물이 만족스럽지 않아서
          </WithdrawalBtn>
          {reason === 4 ? (
            <WithdrawalInputBox>
              <WithdrawalInput
                placeholder=" 구체적일수록 서비스 개선에 많은 도움이 됩니다 "
                {...register("content", {
                  required: "탈퇴 이유을 입력해주세요",
                  minLength: {
                    value: 2,
                    message: "2글자 이상 입력해주세요",
                  },
                })}
              />
              {errors.content && <p>{errors.content.message}</p>}
            </WithdrawalInputBox>
          ) : (
            <WithdrawalBtn
              btcolor="grey"
              onClick={() => {
                setReason(4);
              }}
            >
              기타
            </WithdrawalBtn>
          )}
        </WithdrawalBtBox>
      </WithdrawalReasonBox>
      <MiddleBtn btcolor="orange" id="delete_account" type="submit">
        탈퇴 완료하기
      </MiddleBtn>
    </WithdrawalReasonInner>
  );
};

export default WithdrawalReason;
