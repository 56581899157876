import React, { useEffect, useState } from "react";
import {
  AgreemenCheckbox,
  AgreemenInner,
  AgreementButton,
  AgreementClose,
  AgreementContent,
  AgreementFormBox,
  AgreementFormTitle,
  AgreementFormlist,
  AgreementTitle,
} from "../../styles/Login";
import { MiddleBtn } from "../../styles/Button";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface TosAgreementProps {
  signUpDataToServer: () => void;
  signUpCancel: () => void;
}

const TosAgreement = (props: TosAgreementProps) => {
  const navigate = useNavigate();

  const [content, setContent] = useState<string[]>([]);

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  useEffect(() => {
    Promise.all([
      fetch("./text/tos1.txt").then((response) => response.text()),
      fetch("./text/tos2.txt").then((response) => response.text()),
    ])
      .then(([tos1, tos2]) => setContent([tos1, tos2]))
      .catch((error) => console.error(error));
  }, []);

  const queryClient = useQueryClient();

  const updateSignUpCheck = async () => {
    await queryClient.refetchQueries(["UserByEmail"]);
  };

  const TosChecked = async () => {
    if (isChecked && isChecked2) {
      await props.signUpDataToServer();
      navigate("/change");
      return setTimeout(() => {
        updateSignUpCheck();
      }, 500);
    } else {
      alert("약관에 동의해주세요.");
    }
  };

  return (
    <AgreemenInner>
      <AgreementTitle>약관 동의</AgreementTitle>
      <AgreementFormBox>
        <AgreementClose
          onClick={() => {
            if (window.confirm("회원가입을 취소하시겠습니까?")) {
              props.signUpCancel();
            }
          }}
        />
        <AgreementFormlist>
          <AgreementFormTitle>1. 이용 약관 동의</AgreementFormTitle>
          <AgreementContent>
            <p>{content[0]}</p>
          </AgreementContent>
          <AgreementButton>
            <AgreemenCheckbox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <span>동의합니다.</span>
          </AgreementButton>
        </AgreementFormlist>
        <AgreementFormlist>
          <AgreementFormTitle>2. 개인정보 수집 및 이용 동의</AgreementFormTitle>
          <AgreementContent>
            <p>{content[1]}</p>
          </AgreementContent>
          <AgreementButton>
            <AgreemenCheckbox
              checked={isChecked2}
              onChange={(e) => setIsChecked2(e.target.checked)}
            />
            <span>동의합니다.</span>
          </AgreementButton>
        </AgreementFormlist>
      </AgreementFormBox>
      <MiddleBtn id="signup" btcolor="orange" onClick={TosChecked}>
        변환 시작하기!
      </MiddleBtn>
    </AgreemenInner>
  );
};

export default TosAgreement;
