import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { MiddleBtn } from "src/styles/Button";
import { ChangeCautiontText, ChangeCautiontTitle } from "src/styles/ChangeFace";
import { RequestFormTitle } from "src/styles/Request";
import {
  WithdrawalAcceptBox,
  WithdrawalAcceptBtnBox,
  WithdrawalInner,
} from "src/styles/User";

interface WithdrawalAlertProps {
  setPage: (page: number) => void;
}

const WithdrawalAlert = (props: WithdrawalAlertProps) => {
  const navigate = useNavigate();

  return (
    <WithdrawalInner>
      <Helmet>
        <title>Face Flip | Withdrawal alert</title>
      </Helmet>
      <RequestFormTitle>탈퇴하기</RequestFormTitle>
      <Fade>
        <WithdrawalAcceptBox>
          <ChangeCautiontTitle>※ 주의 사항 ※</ChangeCautiontTitle>
          <ChangeCautiontText>
            탈퇴를 하시면 계정 삭제와 함께 변환하신
            <br />
            모든 사진이 사라지며 복구할 수 없습니다.
            <br />
            계속 하시겠습니까?
          </ChangeCautiontText>
          <WithdrawalAcceptBtnBox>
            <MiddleBtn
              btcolor="grey"
              onClick={() => {
                navigate(-1);
              }}
            >
              아니오
            </MiddleBtn>
            <MiddleBtn
              btcolor="orange"
              onClick={() => {
                props.setPage(1);
              }}
            >
              네
            </MiddleBtn>
          </WithdrawalAcceptBtnBox>
        </WithdrawalAcceptBox>
      </Fade>
    </WithdrawalInner>
  );
};

export default WithdrawalAlert;
