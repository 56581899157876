import React, { useState } from "react";
import {
  ChangeSelectContainer,
  ChangeSelectText,
  SelectFaceInner,
  UploadFaceImg,
  UploadSuccessTitle,
} from "../../styles/ChangeFace";
import plus from "src/assets/images/plus.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setId, setSrc } from "src/store/slice/resultSlice";
import ChangeLoading from "../success/ChangeLoading";
import SelectFace1Box from "./SelectFace1Box";
import SelectFace2Box from "./SelectFace2Box";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import {
  useCreateVContentMutation,
  useGetUserQuery,
  useVFaceByTypeQuery,
} from "src/lib/api";
import { Storage } from "aws-amplify";
import TagManager from "react-gtm-module";

interface SelectFaceProps {
  file: {
    name: string;
    dataUrl: string;
    file?: File | undefined;
  } | null;
  setPage: (page: number) => void;
  error: string;
  setError: (error: string) => void;
}

const SelectFace = (props: SelectFaceProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 유저 불러오기
  const user = useSelector((state: RootState) => state.user);
  // const result = useSelector((state: RootState) => state.result);

  const { data: userData } = useGetUserQuery({ id: user.id! });

  const [step, setStep] = useState(0);

  // 1단계 : 스타일 선택
  const [step1Select, setStep1Select] = useState<{
    id: number;
    gender: string;
    nationality: string;
    face_category: string;
  } | null>(null);
  const [selectVFace, setSelectVFace] = useState("");

  // 2단계 : 얼굴 선택
  const { data } = useVFaceByTypeQuery({ type: selectVFace });

  const [faces, setFaces] = useState<
    (
      | {
          src: string;
          blob: Blob;
          id: string;
          selected: boolean;
        }
      | undefined
    )[]
  >([]);

  // 얼굴 선택 이미지 가져오기
  const getImages = async () => {
    const items = data?.vFaceByType?.items;
    if (items) {
      const faces = await Promise.all(
        items.map(async (item) => {
          const imgKey = item?.image;
          if (imgKey) {
            const img = await Storage.get(imgKey);
            const blob = await fetch(img).then((r) => r.blob());
            return {
              src: img,
              blob: blob,
              id: item?.id,
              selected: false,
            };
          }
        })
      );
      setFaces(faces);
    }
  };

  const handleSelect = (index: number) => {
    const newFaces = faces.map((face, i) => ({
      ...face,
      selected: i === index ? !face!.selected : false,
    })) as { src: string; blob: Blob; id: string; selected: boolean }[];
    setFaces(newFaces);
  };

  const selectedFace = faces.find((face) => face!.selected === true);

  // 로딩창
  const [loading, setLoading] = useState(false);

  const utmSource = sessionStorage.getItem("utm_source");

  // 변환 이미지 등록
  const { mutate } = useCreateVContentMutation({
    onSuccess: (data) => {
      const result = {
        id: data.createVContent?.id!,
        name: data.createVContent?.fileName!,
      };
      dispatch(setId(result));
    },
  });

  // console.log(selectedFace);

  // 태그매니저 events
  const selectFaceArgs = {
    dataLayer: {
      event: "select_face",
      source: utmSource ? utmSource : "direct",
      face_gender: step1Select?.gender,
      face_nationality: step1Select?.nationality,
      face_category: step1Select?.face_category,
      face_id: selectedFace?.id,
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  const errorArgs = {
    dataLayer: {
      event: "error",
      source: utmSource ? utmSource : "direct",
      error_swap: props.error,
    },
  };

  // 변환 api
  const faceUpdate = async () => {
    setLoading(true);
    TagManager.dataLayer(selectFaceArgs);
    const formData = new FormData();
    // const blobImg = await fetch(selectedFace!.src).then((r) => r.blob());
    formData.append("user_id", props?.file?.name!);
    formData.append("src_img", props?.file?.file!);
    formData.append("target_img", selectedFace!.blob);
    await axios({
      method: "post",
      url: "http://api.fliption.us/meta-413/api/v1/images/swap",
      responseType: "blob",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
        token: "token1",
      },
      timeout: 60000,
    })
      .then(async (res) => {
        // console.log("this is result : ", res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const url1 = window.URL.createObjectURL(
          new Blob([props?.file?.file!], {})
        );
        const imgName = "VContent" + Date.now() + ".png";
        await Storage.put(imgName, res.data).catch((err) => {
          console.error("이미지 업로드에 실패하였습니다.", err);
          throw err;
        });
        const userInput = {
          id: userData?.getUser?.id!,
          email: userData?.getUser?.email!,
          snsType: userData?.getUser?.snsType!,
          type: userData?.getUser?.type!,
          name: userData?.getUser?.name!,
          age: userData?.getUser?.age!,
          gender: userData?.getUser?.gender!,
          industryGroup: userData?.getUser?.industryGroup!,
          title: userData?.getUser?.title!,
          needs: userData?.getUser?.needs!,
        };
        mutate({
          input: {
            userID: user.id!,
            user: userInput,
            VFaceID: selectedFace!.id,
            fileName: imgName,
            storage: imgName,
            isBookmarked: false,
            type: String(user.type),
          },
        });
        const result = {
          image: url,
          original: url1,
        };
        dispatch(setSrc(result));
        navigate("/result");
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        // console.log("메세지 : ", e.response);
        props.setError("fail_network");
        TagManager.dataLayer(errorArgs);
        props.setPage(5);
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Face Flip | Virtual face select</title>
      </Helmet>
      {loading ? (
        <ChangeLoading />
      ) : (
        <ChangeSelectContainer>
          <UploadSuccessTitle>
            합성하고 싶은
            <br className="break" /> 가상 얼굴을 선택하세요
          </UploadSuccessTitle>
          <SelectFaceInner>
            <UploadFaceImg src={props?.file?.dataUrl} alt="pic" />
            <img src={plus} alt="+" />
            {step === 0 ? (
              <SelectFace1Box
                step1Select={step1Select}
                setStep1Select={setStep1Select}
                setStep={setStep}
                setSelectVFace={setSelectVFace}
                getImages={getImages}
              />
            ) : (
              <SelectFace2Box
                faces={faces}
                setStep={setStep}
                handleSelect={handleSelect}
                faceUpdate={faceUpdate}
              />
            )}
          </SelectFaceInner>
          {user.type === "기업" ? (
            <ChangeSelectText>
              ※ 체험판에서는 샘플로 10종의 얼굴만 제공됩니다.
              <br className="break" /> 직접 가상 얼굴을 제작하고 싶으시다면{" "}
              <span
                onClick={() => {
                  navigate("/request");
                }}
              >
                무료 테스트 신청
              </span>
              을 클릭해 메모를 남겨주세요.
            </ChangeSelectText>
          ) : (
            <ChangeSelectText>
              ※ 체험판에서는 샘플로 10종의 얼굴만 제공됩니다.
            </ChangeSelectText>
          )}
        </ChangeSelectContainer>
      )}
    </>
  );
};

export default SelectFace;
