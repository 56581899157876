// StyledTheme.ts
import { CSSObject, DefaultTheme, css } from "styled-components";

const colors = {
  primary: "#00418a",
  white: "#fff",
  black: "#000000",
};

const fontSize = {
  title: 20,
  subTitle: 18,
  text: 14,
};

type Media = {
  [key in "desktop" | "tablet" | "mobile" | "small"]: (
    ...args: [CSSObject, ...CSSObject[]]
  ) => ReturnType<typeof css>;
};

const media: Media = {
  desktop: (...args) => css`
    @media (min-width: 1024px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 1023px) {
      ${css(...args)}
    }
  `,
  mobile: (...args) => css`
    @media (max-width: 767px) {
      ${css(...args)}
    }
  `,
  small: (...args) => css`
    @media (max-width: 576px) {
      ${css(...args)}
    }
  `,
};

const flexCol = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const orangeBt = css`
  background-color: #ff6410 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 32px !important;
  font-weight: 800 !important;
  color: #fff !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  &:hover {
    background-color: #e6590e !important;
  }
  @media (prefers-color-scheme: dark) {
    background-color: #ff6410 !important;
    &:hover {
      background-color: #e6590e !important;
    }
  }
`;

const greyBt = css`
  background-color: #5b5b5b !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 32px !important;
  font-weight: 800 !important;
  color: #fff !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  &:hover {
    background-color: #3d3d3d !important;
  }
  @media (prefers-color-scheme: dark) {
    background-color: #5b5b5b !important;
    &:hover {
      background-color: #3d3d3d !important;
    }
  }
`;

const signUpBox = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 7px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
`;

const optionBox = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #f5f6f7;
  border: 7px solid #a1a1a1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
`;

const text_62px = css`
  font-size: 62px;
  line-height: 85px;
  font-weight: 800;
  @media (max-width: 1023px) {
    font-size: 55px;
    line-height: 70px;
  }
  @media (max-width: 767px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media (max-width: 576px) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (max-width: 400px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const theme: DefaultTheme = {
  colors,
  fontSize,
  media,
  flexCol,
  orangeBt,
  greyBt,
  optionBox,
  signUpBox,
  text_62px,
};
