import { Dialog, Grow } from "@mui/material";
import React from "react";
import kakao from "src/assets/images/kakao.png";
import naverImg from "src/assets/images/naver.png";
import google from "src/assets/images/google.png";
import {
  LoginClose,
  LoginContainer,
  LoginSNSBox,
  LoginText,
  LoginTitle,
} from "../../styles/Login";
import { TransitionProps } from "@mui/material/transitions";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { setClose } from "src/store/slice/pageSlice";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";

interface LoginProps {
  handleKakaoLogin: () => void;
  handleNaverLogin: () => void;
  handleGoogleLogin: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

const Login = (props: LoginProps) => {
  const dispatch = useDispatch();

  const loginOpen = useSelector((state: RootState) => state.page.loginOpen);

  const handleClose = () => {
    // props.setOpen(false);
    dispatch(setClose());
  };

  return (
    <>
      <Helmet>
        <title>Face Flip | Login</title>
      </Helmet>
      <Dialog
        onClose={handleClose}
        open={loginOpen}
        TransitionComponent={Transition}
      >
        <LoginContainer>
          <LoginClose onClick={handleClose} />
          <div>
            <LoginTitle>로그인</LoginTitle>
            <LoginText>
              로그인 후<br className="break-m" /> 서비스 이용이 가능합니다.
            </LoginText>
          </div>
          <LoginSNSBox>
            <img
              src={kakao}
              alt="sns"
              onClick={() => {
                dispatch(setClose());
                props.handleKakaoLogin();
              }}
            />
            <img
              src={naverImg}
              alt="sns"
              onClick={() => {
                dispatch(setClose());
                props.handleNaverLogin();
              }}
            />
            <img
              src={google}
              alt="sns"
              onClick={() => {
                dispatch(setClose());
                props.handleGoogleLogin();
              }}
            />
          </LoginSNSBox>
        </LoginContainer>
      </Dialog>
    </>
  );
};

export default Login;
