import styled, { css } from "styled-components";
import changeBg from "src/assets/images/change_bg.png";

// - 변환페이지
export const ChangeContainer = styled.article`
  position: relative;
  display: flex;
  justify-content: center;
  /* height: 100%; */
  /* min-height: 935px; */
  min-height: 95vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${changeBg}) no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  ${(props) => props.theme.media.tablet`
    align-items: center;
    // min-height: 96vh;
    background-repeat: no-repeat;
  `}
  @media screen and (max-width: 400px) {
    padding: 0 5%;
  }
`;
export const ChangeStartBox = styled.div`
  ${({ theme }) => theme.flexCol}
  padding: 245px 5% 3%;
  word-break: keep-all;
  ${(props) => props.theme.media.tablet`
  padding: 50px 3%;
`}
`;
export const ChangeTitle = styled.h2`
  margin-bottom: 23px;
  font-weight: 800;
  font-size: 75px;
  line-height: 98px;
  text-align: center;
  color: #fff;
  ${(props) => props.theme.media.tablet`
    font-size: 65px;
    line-height: 75px;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 50px;
    line-height: 65px;
`}
${(props) => props.theme.media.small`
    font-size: 40px;
    line-height: 50px;
`}
  @media screen and (max-width: 400px) {
    font-size: 35px;
    line-height: 45px;
  }
`;
export const ChangeSubTitle = styled.h3`
  margin-bottom: 75px;
  font-size: 37px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 1250px) {
    font-size: 31px;
    line-height: 40px;
  }
  ${(props) => props.theme.media.mobile`
    margin-bottom: 20%;
    font-size: 25px;
    line-height: 28px;
`}
  ${(props) => props.theme.media.small`
    font-size: 17px;
    line-height: 24px;
`}
`;
export const UploadText = styled.span`
  margin-top: 23px;
  margin-bottom: 209px;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #fff;
  ${(props) => props.theme.media.mobile`
    margin-bottom: 40%;
    font-size: 16px;
    line-height: 20px;
`}
`;
export const UploadText2 = styled.p`
  font-size: 25px;
  line-height: 33px;
  text-align: center;
  color: #929292;
  @media screen and (max-width: 1450px) {
    .break {
      display: block;
    }
  }
  ${(props) => props.theme.media.mobile`
    font-size: 13px;
    line-height: 20px;
`}
`;

// - 변환 주의사항 페이지
// 변환 주의 박스
export const ChangeCautionBox = styled.div`
  ${({ theme }) => theme.optionBox}
  width: 522px;
  height: 407px;
  gap: 38px;
  margin-top: 264px;
  padding: 30px;
  ${(props) => props.theme.media.tablet`
    width: 100%;
    max-width: 450px;
    height: 45%;
    gap: 25px;
    margin: 10% 0;
`}
  ${(props) => props.theme.media.mobile`
    gap: 15px;
    padding: 25px;
`}
`;
export const ChangeCautiontTitle = styled.h3`
  margin-bottom: 23px;
  font-weight: 800;
  font-size: 33px;
  line-height: 36px;
  text-align: center;
  color: #ff6410;
  ${(props) => props.theme.media.tablet`
    margin-bottom: 18px;
`}
  ${(props) => props.theme.media.mobile`
  font-size: 25px;
  line-height: 32px;
`}
`;
export const ChangeCautiontText = styled.p`
  font-weight: 700;
  font-size: 27px;
  line-height: 39px;
  text-align: center;
  color: #9f9f9f;
  ${(props) => props.theme.media.tablet`
    font-size: 22px;
    line-height: 35px;
`}
  ${(props) => props.theme.media.small`
    font-size: 18px;
    line-height: 28px;
`}
  @media screen and (max-width: 400px) {
    font-size: 15px;
  }
`;
// 업로드 성공 컨테이너
export const UploadSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  padding: 83px 45px;
  ${(props) => props.theme.media.tablet`
    padding: 50px 0 100px;
  `}
  @media screen and (max-width: 400px) {
    gap: 20px;
  }
`;
// 업로드 성공 타이틀
export const UploadSuccessTitle = styled.h3`
  font-weight: 800;
  font-size: 55px;
  line-height: 72px;
  text-align: center;
  color: #fff;
  word-break: keep-all;
  ${(props) => props.theme.media.tablet`
  font-size: 48px;
  line-height: 64px;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 36px;
    line-height: 55px;
`}
  @media screen and (max-width: 400px) {
    font-size: 30px;
    line-height: 45px;
  }
`;
// 업로드 성공 이미지
export const UploadSuccessImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  max-width: 352px;
  /* max-width: 500px; */
  height: 530px;
  background: #000;
  border: 8px solid #dadada;
  border-radius: 50px;
  object-fit: cover;
  /* object-fit: scale-down; */
  ${(props) => props.theme.media.tablet`
    max-width: calc(352px * 0.7);
    height: calc(530px * 0.7);
`}
  ${(props) => props.theme.media.mobile`
      max-width: calc(352px * 0.6);
      height: calc(530px * 0.6);
`}
`;
// 업로드 성공 버튼 박스
export const UploadSuccessBtnBox = styled.div`
  display: flex;
  gap: 53.96px;
  margin-top: 26px;
  ${(props) => props.theme.media.tablet`
    gap: 40px;
`}
  ${(props) => props.theme.media.mobile`
  margin-top: 20px;
  gap: 30px;
`}
${(props) => props.theme.media.small`
    gap: 20px;
`}
`;

// - 변환 선택 페이지
export const ChangeSelectContainer = styled.div`
  ${({ theme }) => theme.flexCol}
  position: relative;
  margin: 78px 4% 20px;
  width: 100%;
  ${(props) => props.theme.media.tablet`
  margin-top: 45px;
  `}
`;
export const ChangeSelectText = styled.p`
  font-size: 25px;
  line-height: 33px;
  text-align: center;
  color: #fff;
  word-break: keep-all;
  span {
    color: #ff6410;
    cursor: pointer;
  }
  @media screen and (max-width: 1450px) {
    .break {
      display: block;
    }
  }
  ${(props) => props.theme.media.tablet`
    font-size: 18px;
    line-height: 28px;
`}
  ${(props) => props.theme.media.mobile`
    font-size: 13px;
    line-height: 18px;
`}
`;
export const SelectFaceInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 1620px;
  margin: 81.68px 0 157px 0;
  @media screen and (max-width: 1600px) {
    flex-direction: column;
    gap: 20px;
  }
  ${(props) => props.theme.media.tablet`
  margin: 50px 0 130px;
  `}
  ${(props) => props.theme.media.mobile`
  margin-top: 30px;
  `}
`;
export const UploadFaceImg = styled.img`
  width: 313px;
  height: 471px;
  border: 8px solid #ebebeb;
  background: #000;
  border-radius: 40px;
  object-fit: scale-down;
  @media screen and (max-width: 1600px) {
    /* width: calc(313px * 0.8); */
    /* height: calc(471px * 0.8); */
    width: 450px;
    height: 100%;
  }
  ${(props) => props.theme.media.tablet`
    border: 5px solid #ebebeb;
  `}
  ${(props) => props.theme.media.mobile`
    width: 313px;
  `}
    @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 270px;
  }
`;
export const SelectFace1List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1);
  /* gap: 20px; */
  width: 100%;
  /* max-width: 1196px; */
  text-align: center;
  div {
    width: 100%;
    padding: 10px;
  }
  div > span {
    width: 100%;
    max-width: 590px;
    height: 80px;
    /* border: 4px solid #818181; */
    font-size: 28px;
    font-weight: 800;
    line-height: 72px;
  }
  > div:nth-child(1) {
    border-right: 1px solid #acacac;
  }
  > div:nth-child(1),
  > div:nth-child(2) {
    /* max-width: 592px; */
    border-bottom: 1px solid #acacac;
    grid-column: span 2;
    span {
      height: 80px;
    }
  }
  > div:nth-child(n + 3):nth-child(-n + 6) {
    border-bottom: 1px solid #acacac;
    span {
      max-width: 287px;
      height: 71px;
      line-height: 63px;
    }
  }
  > div:nth-child(4) {
    border-left: 1px solid #acacac;
    border-right: 1px solid #acacac;
  }
  > div:nth-child(5) {
    border-right: 1px solid #acacac;
  }
  > div:nth-child(8),
  > div:nth-child(12),
  > div:nth-child(16) {
    border-left: 1px solid #acacac;
    border-right: 1px solid #acacac;
  }
  > div:nth-child(9),
  > div:nth-child(13),
  > div:nth-child(17) {
    border-right: 1px solid #acacac;
  }
  ${(props) => props.theme.media.tablet`
    div > span {
    font-size: 25px;
    }
    > div:nth-child(1),
    > div:nth-child(2) {
      span {
        font-size: 27px;
        height: 70px;
        line-height: 62px;
      }
    }
  `}
  ${(props) => props.theme.media.mobile`
    div {
    padding: 5px;
      span {
        font-size: 18px;
      }
    }
    > div:nth-child(1),
    > div:nth-child(2) {
      span {
        height: 55px;
        font-size: 21px;
        line-height: 47px;
      }
    }
    > div:nth-child(n + 3):nth-child(-n + 6) {
      span {
        height: 50px;
        line-height: 42px;
      }
    }
  `}
  @media screen and (max-width: 400px) {
    div > span {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 300px) {
    div {
      padding: 1px;
      border: none !important;
      span {
        font-size: 12px;
      }
    }
    > div:nth-child(1),
    > div:nth-child(2) {
      font-size: 16px;
    }
  }
`;
export const SelectFace2List = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 24px;
  width: 100%;
  max-width: 1196px;
  height: 471px;
  margin: 0 auto;
  @media screen and (max-width: 1600px) {
    height: 444px;
    grid-gap: 20px;
  }

  ${(props) => props.theme.media.mobile`
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    justify-items: center;
    grid-gap: 13px;
    > div:nth-child(1) {
      order: 1;
      justify-self: right;
    }
    > div:nth-child(6) {
      order: 2;
      justify-self: left;
    }
    > div:nth-child(2) {
      order: 3;
      justify-self: right;
    }
    > div:nth-child(7) {
      order: 4;
      justify-self: left;
    }
    > div:nth-child(3) {
      order: 5;
      justify-self: right;
    }
    > div:nth-child(8) {
      order: 6;
      justify-self: left;
    }
    > div:nth-child(4) {
      order: 7;
      justify-self: right;
    }
    > div:nth-child(9) {
      order: 8;
      justify-self: left;
    }
    > div:nth-child(5) {
      order: 9;
      justify-self: right;
    }
    > div:nth-child(10) {
      order: 10;
      justify-self: left;
    }
  `}
`;
export const SelectFaceImgBox = styled.div<{ selected: string }>`
  position: relative;
  width: 100%;
  max-width: 220px;
  height: 100%;
  max-height: 220px;
  cursor: pointer;
  > div {
    position: absolute;
    /* width: calc(100% - 5px);
    height: calc(100% - 5px); */
    width: 100%;
    height: 100%;
    border: 5px solid #ebebeb;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }
  > img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  ${(props) => {
    if (props.selected === "selected") {
      return css`
        > div {
          border: 7px solid #ff6410;
        }
      `;
    } else if (props.selected === "none") {
      return css`
        > div {
          border: 5px solid #ebebeb;
          background: rgba(0, 0, 0, 0.65);
        }
      `;
    }
  }}
  @media screen and (max-width: 1600px) {
    max-width: 200px;
    max-height: 200px;
  }
  ${(props) => props.theme.media.mobile`
  max-width: 150px;
  max-height: 150px;
`}
`;
