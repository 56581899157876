import React from "react";
import { Fade } from "react-awesome-reveal";
import { useListVFacesQuery } from "src/lib/api";
import { SelectButton } from "src/styles/Button";
import { SelectFace2List, SelectFaceImgBox } from "src/styles/ChangeFace";

interface SelectFace2BoxProps {
  faces: (
    | {
        src: string;
        id: string;
        // blob: Blob;
        selected: boolean;
      }
    | undefined
  )[];
  setStep: (step: number) => void;
  handleSelect: (index: number) => void;
  faceUpdate: () => void;
}

const SelectFace2Box = (props: SelectFace2BoxProps) => {
  const selectedFace = props.faces.find((face) => face!.selected === true);

  // const { data, isLoading } = useListVFacesQuery();

  return (
    <SelectFace2List>
      <Fade triggerOnce>
        {props.faces.map((face, index) => (
          <SelectFaceImgBox
            key={index}
            selected={
              selectedFace ? (face!.selected ? "selected" : "none") : ""
            }
            onClick={() => props.handleSelect(index)}
          >
            <div></div>
            <img src={face!.src} alt={`face_${index + 1}`} />
          </SelectFaceImgBox>
        ))}
      </Fade>
      {selectedFace ? (
        <SelectButton
          id="select_face"
          btcolor="orange"
          onClick={() => {
            props.faceUpdate();
          }}
        >
          해당 얼굴 사용
        </SelectButton>
      ) : (
        <SelectButton
          btcolor="orange"
          onClick={() => {
            props.setStep(0);
          }}
        >
          이전
        </SelectButton>
      )}
    </SelectFace2List>
  );
};

export default SelectFace2Box;
