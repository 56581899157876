import React from "react";
import Header from "./pages/Header";
import SwitchPage from "./pages/SwitchPage";
import withRoot from "./withRoot";
import TagManager from "react-gtm-module";
import MainSelect from "./features/main/MainSelect";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";

function App() {
  // GTM
  const tagManagerArgs = {
    gtmId: "GTM-TX9G4S6",
  };

  TagManager.initialize(tagManagerArgs);

  // 개인 및 브랜드
  const mainType = useSelector((state: RootState) => state.user.mainType);

  return (
    <div>
      {mainType ? (
        <>
          <Header />
          <SwitchPage />
        </>
      ) : (
        <MainSelect />
      )}
    </div>
  );
}

export default withRoot(App);
