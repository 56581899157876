import { createTheme } from "@mui/material";

// const regular = {
//   fontFamily: "NanumSquareAcr",
//   fontStyle: "normal",
//   fontWeight: "normal",
//   fontSize: 14,
//   src: `
//     url(${NanumSquareAcr}) format('opentype')
//   `,
// };

// Create a theme instance.
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "'NanumSquareAcr', sans-serif",
          "& *": {
            margin: 0,
            padding: 0,
          },
          "& div.break-m": {
            display: "block",
          },
          "& br.break": {
            display: "none",
          },
          "& br.break-m": {
            display: "none",
          },
        },
        ul: {
          margin: 0,
          listStyle: "none",
        },
        ol: {
          listStyle: "none",
        },
        input: {
          fontFamily: "'NanumSquareAcr', sans-serif",
        },
        "@media only screen and (max-width: 1023px)": {
          body: {
            "& br.break": {
              display: "block",
            },
          },
        },
        "@media only screen and (max-width: 576px)": {
          body: {
            "& br.break-m": {
              display: "block",
            },
            "& div.break-m": {
              display: "none",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "NanumSquareAcr",
        },
        variants: [
          {
            props: { variant: "orange" },
            style: {
              background: "#ff6410",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 32,
              fontWeight: 800,
              color: "#fff",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                background: "#E6590E",
              },
            },
          },
          {
            props: { variant: "grey" },
            style: {
              background: "#5B5B5B",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 32,
              fontWeight: 800,
              color: "#fff",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                background: "#3D3D3D",
              },
            },
          },
        ],
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxSizing: "border-box",
          background: "#F5F6F7",
          border: "7px solid #A1A1A1",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "46px",
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          fontSize: "102px",
          // fontWeight: 700,
        },
        iconFilled: {
          color: "#ff6410",
        },
        iconHover: {
          color: "#ff6410",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "'NanumSquareAcr', sans-serif",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#373737",
          color: "#fff",
          fontFamily: "'NanumSquareAcr', sans-serif",
          fontWeight: "600 !important",
          // ".MuiMenuItem-root:hover": {
          //   backgroundColor: "#778583 !important",
          // },
          // ".Mui-selected": {
          //   backgroundColor: "#ff6410 !important",
          //   "&:hover": {
          //     background: "#E6590E !important",
          //   },
          // },
        },
      },
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#ff6410",
      dark: "#ff6410",
    },
  },
});

export default theme;
