import React, { useEffect, useRef } from "react";
import {
  UploadSuccessBtnBox,
  UploadSuccessContainer,
  UploadSuccessImg,
  UploadSuccessTitle,
} from "../../styles/ChangeFace";
import { MiddleBtn } from "../../styles/Button";
import { Helmet } from "react-helmet-async";

interface ChangeUploadProps {
  file: {
    name: string;
    dataUrl: string;
    file?: File | undefined;
  } | null;
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPage: (page: number) => void;
}

const ChangeUpload = (props: ChangeUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <UploadSuccessContainer>
      <Helmet>
        <title>Face Flip | File upload succeed</title>
      </Helmet>
      <UploadSuccessTitle>업로드 성공!</UploadSuccessTitle>
      <UploadSuccessImg src={props?.file?.dataUrl} alt="pic" />
      <UploadSuccessBtnBox>
        <MiddleBtn btcolor="grey" onClick={handleButtonClick}>
          사진 다시 선택
        </MiddleBtn>
        <MiddleBtn
          btcolor="orange"
          onClick={() => {
            props.setPage(4);
          }}
        >
          해당 사진 사용
        </MiddleBtn>
      </UploadSuccessBtnBox>
      <input
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        id="sideImageUpload"
        onChange={props.handleUpload}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </UploadSuccessContainer>
  );
};

export default ChangeUpload;
