import { Drawer } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MobileInner, MobileMenuText } from "src/styles/Header";
import { RootState } from "src/store/store";
import { useDispatch } from "react-redux";
import { setOpen } from "src/store/slice/pageSlice";
import TagManager from "react-gtm-module";

interface MobileNavProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setLinkOpen: (open: boolean) => void;
  logout: () => void;
}

const MobileNav = (props: MobileNavProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mainType = useSelector((state: RootState) => state.user.mainType);
  const user = useSelector((state: RootState) => state.user);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      props.setOpen(open);
    };

  // 태그매니저 events
  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  const modelArgs = {
    dataLayer: {
      event: "apply_model",
      page_title: htmlTitle?.innerText,
      source: utmSource ? utmSource : "direct",
      model_age: user.age,
      model_gender: user.gender,
      model_needs: user.needs,
    },
  };

  const myPageArgs = {
    dataLayer: {
      event: "mypage",
      source: utmSource ? utmSource : "direct",
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_name: user.name,
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  // 플립션 모델 지원
  const applyModel = () => {
    TagManager.dataLayer(modelArgs);
    window.open("https://forms.gle/KFrSUh253bdZAWD88", "_blank");
  };

  const needsLogin = () => {
    alert("로그인이 필요합니다");
    dispatch(setOpen());
  };

  return (
    <Drawer
      className="mobile"
      anchor="right"
      open={props.open}
      onClose={toggleDrawer(false)}
    >
      <nav>
        <MobileInner>
          <MobileMenuText
            color="grey"
            onClick={() => {
              props.setOpen(false);
              props.setLinkOpen(true);
            }}
          >
            플립션소개
          </MobileMenuText>
          {user.authenticated && (
            <MobileMenuText
              id="mypage"
              color="grey"
              onClick={() => {
                props.setOpen(false);
                TagManager.dataLayer(myPageArgs);
                navigate("/mypage");
              }}
            >
              마이페이지
            </MobileMenuText>
          )}
          {user.authenticated ? (
            <MobileMenuText
              color="grey"
              onClick={() => {
                props.setOpen(false);
                props.logout();
              }}
            >
              로그아웃
            </MobileMenuText>
          ) : (
            <MobileMenuText
              color="grey"
              onClick={() => {
                props.setOpen(false);
                // props.setLogin(true);
                dispatch(setOpen());
              }}
            >
              로그인
            </MobileMenuText>
          )}
          {user.authenticated ? (
            user.type === "기업" ? (
              <MobileMenuText
                color="orange"
                onClick={() => {
                  props.setOpen(false);
                  navigate("/request");
                }}
              >
                무료 테스트 신청
              </MobileMenuText>
            ) : (
              <MobileMenuText
                id="apply_model"
                color="orange"
                onClick={() => {
                  props.setOpen(false);
                  user.authenticated ? applyModel() : needsLogin();
                }}
              >
                플립션 모델풀 지원
              </MobileMenuText>
            )
          ) : mainType === "company" ? (
            <MobileMenuText
              color="orange"
              onClick={() => {
                props.setOpen(false);
                navigate("/request");
              }}
            >
              무료 테스트 신청
            </MobileMenuText>
          ) : (
            <MobileMenuText
              id="apply_model"
              color="orange"
              onClick={() => {
                props.setOpen(false);
                user.authenticated ? applyModel() : needsLogin();
              }}
            >
              플립션 모델풀 지원
            </MobileMenuText>
          )}
        </MobileInner>
      </nav>
    </Drawer>
  );
};

export default MobileNav;
