import React from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/store";
import { ChangeButton } from "src/styles/Button";
import { MainSubTitle, MainTitle } from "src/styles/Main";

const MainBrandTitle = () => {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  const htmlTitle = document.querySelector("title");

  const utmSource = sessionStorage.getItem("utm_source");

  // 태그매니저 events
  const tagManagerArgs = {
    dataLayer: {
      event: "swap",
      source: utmSource ? utmSource : "direct",
      page_title: htmlTitle?.innerText,
      brand_name: user.name,
      brand_industry: user.industryGroup,
      brand_position: user.title,
      brand_needs: user.needs,
    },
  };

  return (
    <>
      <div>
        <MainTitle>
          원하는 이미지가 없거나,
          <br className="break-m" /> 시간이 안 맞거나,
          <br />
          그동안 모델 구하기
          <br className="break-m" /> 힘드셨죠?
        </MainTitle>
        <MainSubTitle>
          <span>
            플립션은 마케팅, 패션 모델, 콘텐츠에 필요한 ‘원하는 얼굴’을 만들고
            합성해드립니다.
          </span>
          <br />
          <span>
            외국인 모델을 구할 필요 없이 얼굴만 외국인으로 바꿀 수도 있습니다.
          </span>
          <br />
          <span>
            클릭 한 번으로 손쉽게 커스터마이징하는 모델! 지금 바로 시작하세요 :)
          </span>
        </MainSubTitle>
      </div>
      <ChangeButton
        color="primary"
        id="swap"
        onClick={() => {
          user.authenticated && TagManager.dataLayer(tagManagerArgs);
          navigate("/change");
        }}
      >
        버추얼 모델 제작 체험하기
      </ChangeButton>
    </>
  );
};

export default MainBrandTitle;
