import React, { useEffect, useState } from "react";
import example from "src/assets/images/change_bg.png";
import arrow from "src/assets/images/arrow.png";
import {
  ResultBtBox,
  ResultImgBox,
  ResultImgContainer,
  ResultImgTitle,
  ResultInner,
  ResultSubTitle,
  ResultTitle,
} from "../../styles/Success";
import { SaveButton } from "../../styles/Button";
import ServiceRating from "./ServiceRating";
import { ChangeContainer } from "../../styles/ChangeFace";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { Helmet } from "react-helmet-async";
import TagManager from "react-gtm-module";

const Result = () => {
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(false);

  const user = useSelector((state: RootState) => state.user);
  const result = useSelector((state: RootState) => state.result);

  // gtm event
  const htmlTitle = document.querySelector("title");
  const utmSource = sessionStorage.getItem("utm_source");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSource ? utmSource : "direct",
      },
    });
  }, []);

  const saveArgs = {
    dataLayer: {
      event: "save_result",
      source: utmSource ? utmSource : "direct",
      page_title: htmlTitle?.innerText,
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };
  const swapArgs = {
    dataLayer: {
      event: "swap",
      source: utmSource ? utmSource : "direct",
      page_title: htmlTitle?.innerText,
      swap_result: "succeed",
      ...(user.type === "기업"
        ? {
            brand_name: user.name,
            brand_industry: user.industryGroup,
            brand_position: user.title,
            brand_needs: user.needs,
          }
        : {
            model_age: user.age,
            model_gender: user.gender,
            model_needs: user.needs,
          }),
    },
  };

  return (
    <ChangeContainer>
      <Helmet>
        <title>Face Flip | Result</title>
      </Helmet>
      <ResultInner>
        <ResultTitle>변환된 결과를 확인해주세요</ResultTitle>
        <ResultSubTitle>
          변환본은 마이페이지에서도
          <br className="break" /> 확인 및 저장 가능합니다!
        </ResultSubTitle>
        <ResultImgContainer>
          <ResultImgBox border="white">
            <ResultImgTitle color="#fff">원본</ResultImgTitle>
            <div>
              <img
                src={result.original ? result.original : example}
                alt="img"
              />
            </div>
          </ResultImgBox>
          <img src={arrow} alt="arrow" />
          <div style={{ position: "relative" }}>
            <ResultImgBox border="orange">
              <ResultImgTitle color="#ff6410">변환본</ResultImgTitle>
              <div>
                <img src={result.image ? result.image : example} alt="img" />
              </div>
            </ResultImgBox>
            <ResultBtBox>
              <SaveButton
                btcolor="grey"
                id="swap"
                onClick={() => {
                  TagManager.dataLayer(swapArgs);
                  setChange(true);
                  setOpen(true);
                }}
              >
                다시
                <br className="break-m" /> 변환하기
              </SaveButton>
              <SaveButton
                btcolor="orange"
                id="save_result"
                onClick={() => {
                  TagManager.dataLayer(saveArgs);
                  setChange(false);
                  setOpen(true);
                }}
              >
                저장하기
              </SaveButton>
            </ResultBtBox>
          </div>
        </ResultImgContainer>
        <ServiceRating
          open={open}
          setOpen={setOpen}
          src={example}
          change={change}
        />
      </ResultInner>
    </ChangeContainer>
  );
};

export default Result;
