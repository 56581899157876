import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  mainType: string | null;
  authenticated: boolean;
  sns: string | null;
  id: string | null;
  type: String | null;
  name: string | null;
  age: string | null;
  gender: string | null;
  industryGroup: string | null;
  title: string | null;
  needs: (string | null | undefined)[] | null;
}

const initialState: AuthState = {
  mainType: localStorage.getItem("mainType"),
  authenticated: false,
  sns: null,
  id: null,
  type: null,
  name: null,
  age: null,
  gender: null,
  industryGroup: null,
  title: null,
  needs: null,
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMainType: (state, action: PayloadAction<string>) => {
      state.mainType = action.payload;
      localStorage.setItem("mainType", action.payload);
    },
    setSNS: (state, action: PayloadAction<{ sns: string }>) => {
      state.sns = action.payload.sns;
    },
    setId: (state, action: PayloadAction<{ id: string }>) => {
      state.id = action.payload.id;
    },
    setAuth: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        sns: string | null;
        id: string | null;
        type?: string | null;
        name?: string | null;
        age?: string | null;
        gender?: string | null;
        industryGroup?: string | null;
        title?: string | null;
        needs?: (string | null | undefined)[] | null;
      }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearAuth: (state) => {
      return initialState;
    },
  },
});

export const { setMainType, setSNS, setId, setAuth, clearAuth } =
  userSlice.actions;

export default userSlice.reducer;
