import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MiddleBtn } from "src/styles/Button";
import { SignupForm, SignupInner, SignupTitle } from "src/styles/Login";
import { MyPageContainer } from "src/styles/User";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import PersonalSignup from "./PersonalSignup";
import CompanySignup from "./CompanySignup";
import { SelectChangeEvent } from "@mui/material";
import { useUpdateUserMutation } from "src/lib/api";
import TosAgreement from "./TosAgreement";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { clearAuth, setAuth } from "src/store/slice/userSlice";
import TagManager from "react-gtm-module";

export interface SignupFormType {
  name: string;
  age: number;
  etc: string;
}

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SignupFormType>();

  // gtm event
  const htmlTitle = document.querySelector("title");
  const utmSource = sessionStorage.getItem("utm_source");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page_title: htmlTitle?.innerHTML,
        source: utmSource ? utmSource : "direct",
      },
    });
  }, []);

  // 회원가입 단계
  const [step, setStep] = useState(0);

  // 회원가입
  const { mutate } = useUpdateUserMutation({
    onError: (error) => {
      console.log(error);
      alert("회원가입에 실패했습니다. 다시 시도해주세요.");
      signUpCancel();
    },
  });
  // const { mutate: deleteMutate } = useDeleteUserMutation();

  // 회원가입 정보
  const [gender, setGender] = useState(0);
  const [needs, setNeeds] = useState<string[]>([]);
  const [job, setJob] = useState<string>("");
  const [position, setPosition] = useState<string>("");

  const handleJobChange = (event: SelectChangeEvent<unknown>) => {
    setJob(event.target.value as string);
  };
  const handlePositionChange = (event: SelectChangeEvent<unknown>) => {
    setPosition(event.target.value as string);
  };
  const handleNeedsChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setNeeds(
      typeof value === "string" ? value.split(",") : (value as string[])
    );
  };

  const isEtcIncluded = needs.includes("기타");

  useEffect(() => {
    if (!isEtcIncluded) {
      reset({ etc: "" });
    }
  }, [isEtcIncluded]);

  const onSubmit: SubmitHandler<SignupFormType> = (submit) => {
    if (user.mainType === "company") {
      if (job === "" || position === "" || needs.length === 0) {
        alert("항목을 선택해주세요");
      } else {
        // console.log(
        //   "type : brand",
        //   submit,
        //   "industryGroup : ",
        //   job,
        //   "title : ",
        //   position,
        //   needs
        // );
        // navigate("/agreement");
        setStep(1);
      }
    } else {
      if (gender === 0 || needs.length === 0) {
        alert("항목을 선택해주세요");
      } else {
        // console.log("type : personal", submit, gender, needs);
        setStep(1);
      }
    }
  };

  const signUpDataToServer = async () => {
    const formData = getValues();
    if (user.mainType === "company") {
      // console.log(formData, job, "title : ", position, needs);
      const filteredNeeds = [
        ...needs.filter((need) => need !== "기타"),
        formData.etc,
      ];
      if (isEtcIncluded) {
        await mutate({
          input: {
            id: user.id!,
            type: "기업",
            name: formData.name,
            industryGroup: job,
            title: position,
            needs: filteredNeeds,
            source: utmSource ? utmSource : "direct",
          },
        });
      } else {
        await mutate({
          input: {
            id: user.id!,
            type: "기업",
            name: formData.name,
            industryGroup: job,
            title: position,
            needs: needs,
            source: utmSource ? utmSource : "direct",
          },
        });
      }
      const result = {
        authenticated: true,
        id: user.id,
        sns: user.sns,
        type: "기업",
        name: formData.name,
        industryGroup: job,
        title: position,
        needs: isEtcIncluded ? filteredNeeds : needs,
      };
      dispatch(setAuth(result));
      const signupArgs = {
        dataLayer: {
          event: "signup",
          source: utmSource ? utmSource : "direct",
          brand_name: formData.name,
          brand_industry: job,
          brand_position: position,
          brand_needs: isEtcIncluded ? filteredNeeds : needs,
        },
      };
      TagManager.dataLayer(signupArgs);
    } else {
      // console.log("type : personal", formData, gender, needs);
      const filteredNeeds = [
        ...needs.filter((need) => need !== "기타"),
        formData.etc,
      ];
      if (isEtcIncluded) {
        await mutate({
          input: {
            id: user.id!,
            type: "개인",
            name: formData.name,
            age: formData.age.toString(),
            gender: gender === 1 ? "male" : "female",
            needs: filteredNeeds,
            source: utmSource ? utmSource : "direct",
          },
        });
      } else {
        await mutate({
          input: {
            id: user.id!,
            type: "개인",
            name: formData.name,
            age: formData.age.toString(),
            gender: gender === 1 ? "male" : "female",
            needs: needs,
            source: utmSource ? utmSource : "direct",
          },
        });
      }
      const result = {
        authenticated: true,
        id: user.id,
        sns: user.sns,
        type: "개인",
        name: formData.name,
        age: formData.age.toString(),
        gender: gender === 1 ? "male" : "female",
        needs: isEtcIncluded ? filteredNeeds : needs,
      };
      dispatch(setAuth(result));
      const signupArgs = {
        dataLayer: {
          event: "signup",
          source: utmSource ? utmSource : "direct",
          model_name: formData.name,
          model_age: formData.age.toString(),
          model_gender: gender === 1 ? "male" : "female",
          model_needs: isEtcIncluded ? filteredNeeds : needs,
        },
      };
      TagManager.dataLayer(signupArgs);
    }
  };

  // 회원가입 취소 기능
  const signUpCancel = async () => {
    dispatch(clearAuth());
    navigate("/");
    try {
      await Auth.signOut();
    } catch (err) {
      console.log(err);
    }
  };

  const PersonalProps = {
    register,
    errors,
    needs,
    gender,
    setGender,
    signUpCancel,
    handleChange: handleNeedsChange,
  };

  const CompanyProps = {
    register,
    errors,
    job,
    handleJobChange,
    position,
    handlePositionChange,
    needs,
    signUpCancel,
    handleChange: handleNeedsChange,
  };

  return (
    <MyPageContainer>
      {step === 0 ? (
        <SignupInner>
          {user.mainType === "company" ? (
            <SignupTitle>담당자님, 알려주세요!</SignupTitle>
          ) : (
            <SignupTitle>모델님, 알려주세요!</SignupTitle>
          )}
          <SignupForm onSubmit={handleSubmit(onSubmit)}>
            {user.mainType === "company" ? (
              <CompanySignup {...CompanyProps} />
            ) : (
              <PersonalSignup {...PersonalProps} />
            )}
            <MiddleBtn btcolor="orange" type="submit">
              다음
            </MiddleBtn>
          </SignupForm>
        </SignupInner>
      ) : (
        <TosAgreement
          signUpDataToServer={signUpDataToServer}
          signUpCancel={signUpCancel}
        />
      )}
    </MyPageContainer>
  );
};

export default SignUp;
